<template>
  <sdPageHeader title="User Management">
    <template v-slot:subTitle>
      <!-- <span class="" style="margin-left: 23px">{{projectData.length}} R </span> -->
    </template>
    <template v-slot:buttons>
      <sdButton @click="onClickAddUser" key="1" type="primary">
        <sdFeatherIcons type="plus" size="16" /> Add User
      </sdButton>
    </template>
  </sdPageHeader>

  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <div class="" style="display: flex; justify-content: space-between">
        <ProjectSorting>
          <div class="project-sort-bar">
            <div class="project-sort-search">
              <sdAutoComplete @change="onUserSearch" :dataSource="searchData" placeholder="Search User By Name" patterns />
            </div>
          </div>
        </ProjectSorting>
         <!-- <div class="">
            <span> Project Type : </span>
            <a-switch @change="onSwitchChange" style="margin-left: 15px" v-model:checked="projectType" checked-children="Project" un-checked-children="Template" />
          </div> -->
        </div>
        <div :key="updateTableKey">
          <a-table :dataSource="users" :columns="columns">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'role'">
                <a-tag v-if="text === 'Customer'" color="success">{{text}}</a-tag>
                <a-tag v-if="text === 'Consultant'" color="warning">{{text}}</a-tag>
                <a-tag v-if="text === 'Admin'" color="processing">{{text}}</a-tag>
              </template>
              <template v-if="column.dataIndex === 'operation'">
                 <div style="margin-right: 8px" class="editable-row-operations">
                <span>
                  <a  @click="onClickEditUser(record)">Edit</a> | 
                  <a-popconfirm title="Sure to delete?" @confirm="handleDeleteUser(record._id)">
                    <a>Delete</a>
                  </a-popconfirm>
                </span>
              </div>
              </template>
            </template>
          </a-table>
          <a-modal v-model:visible="addModalVisible" title="Add User">
            <template #footer>
              <a-button key="back" @click="() => { addModalVisible = false }">Return</a-button>
              <a-button key="submit" type="primary" :loading="caseLoading" @click="handleAddUser">Add User</a-button>
            </template>
            <a-form
              ref="modalFormRef"
              :model="formState"
              name="basic"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
            >
              <a-form-item
                label="Email"
                name="email"
                :rules="[{ required: true, message: 'Please input email!' }]"
              >
                <a-input v-model:value="formState.email" />
              </a-form-item>
              <a-form-item
                label="Username"
                name="username"
                :rules="[{ required: true, message: 'Please input username!' }]"
              >
                <a-input v-model:value="formState.username" />
              </a-form-item>
              <a-form-item
                label="Password"
                name="password"
                :rules="[{ required: true, message: 'Please input password!' }]"
              >
                <a-input-password v-model:value="formState.password" />
              </a-form-item>
               <a-form-item
                label="Role"
                name="role"
                :rules="[{ required: true, message: 'Please input role!' }]"
              >
                <a-select
                  ref="select"
                  v-model:value="formState.role"
                  style="width: 100%"
                >
                  <a-select-option value="Customer">Customer</a-select-option>
                  <a-select-option value="Consultant">Consultant</a-select-option>
                  <a-select-option value="Admin" >Admin</a-select-option>
                </a-select>
                <!-- <a-input v-model:value="formState.role" /> -->
              </a-form-item>
            </a-form>
          </a-modal>
          <a-modal v-model:visible="editModalVisible" title="Edit User">
            <template #footer>
              <a-button key="back" @click="() => { editModalVisible = false }">Return</a-button>
              <a-button key="submit" type="primary" :loading="caseLoading" @click="handleEditUser">Update User</a-button>
            </template>
            <a-form
              ref="modalFormRef"
              :model="formState"
              name="basic"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
            >
              <a-form-item
                label="Email"
                name="email"
                :rules="[{ required: true, message: 'Please input email!' }]"
              >
                <a-input v-model:value="formState.email" />
              </a-form-item>
              <a-form-item
                label="Username"
                name="username"
                :rules="[{ required: true, message: 'Please input username!' }]"
              >
                <a-input v-model:value="formState.username" />
              </a-form-item>
              <a-form-item
                label="Password"
                name="password"
              >
                <a-input-password v-model:value="formState.password" />
              </a-form-item>
               <a-form-item
                label="Role"
                name="role"
                :rules="[{ required: true, message: 'Please input role!' }]"
              >
                <a-select
                  ref="select"
                  v-model:value="formState.role"
                  style="width: 100%"
                >
                  <a-select-option value="Customer">Customer</a-select-option>
                  <a-select-option value="Consultant">Consultant</a-select-option>
                  <a-select-option value="Admin" >Admin</a-select-option>
                </a-select>
                <!-- <a-input v-model:value="formState.role" /> -->
              </a-form-item>
            </a-form>
          </a-modal>
          <!-- <ProjectLists /> -->
        </div>
      </a-col>
    </a-row>
    <!-- <project-create :onCancel="onCancel" :visible="visible" /> -->
  </Main>
</template>
<script>
import { ProjectHeader, ProjectSorting } from './style';
import { Main } from '../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

const UserManagement = {
  name: 'User Management',
  components: { ProjectHeader, Main, ProjectSorting },
  setup() {
    const { state, dispatch } = useStore();
    const searchData = computed(() => state.headerSearchData);
    const projectData = computed(() => state.project.projectData);
    const { path } = useRoute().matched[1];
    const projectType = computed(() => state.project.projectType);
    const users = computed(() => state.auth.filteredUsers);
    const visible = ref(false);
    const addModalVisible = ref(false);
    const editModalVisible = ref(false);
    const categoryActive = ref('all');
    const updateTableKey = ref(0);
    const selectedUserId = ref();

    onMounted(() => { dispatch('fetchUsers') })

    const onUserSearch = (searchValue) => {
      console.log(searchValue, 'searchvalue')
      dispatch('userSearchAction', searchValue)
    }

    watch (() => state.auth.users, function () {
      console.log('users updated')
      updateTableKey.value += 1
    });

    const onClickAddUser = () => {
      addModalVisible.value = true;
    }

    const handleAddUser = () => {
      console.log(formState, 'formstate')
      dispatch('signup', formState)
      addModalVisible.value = false;
      formState.username = null;
      formState.email = null;
      formState.password = null;
      formState.role = null;
    }

    const onClickEditUser = (payload) => {
      console.log(payload, 'payload')
      formState.username = payload.username
      formState.email = payload.email
      formState.role = payload.role
      selectedUserId.value = payload._id
      editModalVisible.value = true;
    }

    const handleDeleteUser = (id) => {
      dispatch('removeUser', id)
    }

    const handleEditUser = () => {
      const payload = {
        id: selectedUserId.value,
        data: {
          username: formState.username,
          email: formState.email,
          password: formState.password,
          role: formState.role,
        }
      }
      console.log(payload.data, 'formstate')
      dispatch('editUser', payload)
      editModalVisible.value = false;
      formState.username = null;
      formState.email = null;
      formState.password = null;
      formState.role = null;
    }

    const formState = reactive({
      username: null,
      email: null,
      password: null,
      role: null,
    });

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Name',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        key: 'operation',
      },
    ]

    const onSorting = (selectedItems) => {
      dispatch('sortingProjectByCategory', selectedItems);
    };
    

    const onSwitchChange = (val) => {
      console.log(val, 'val')
      dispatch('fetchProjectsQuery', !val)
      state.project.projectType = val
    }

    const onChangeCategory = (value) => {
      categoryActive.value = value;
      dispatch('filterProjectByStatus', value);
    };

    const onProjectSearch = (searchValue) => {
      console.log(searchValue, 'searchvalue')
      dispatch('projectSearchAction', searchValue)
    }
    

    const showModal = () => {
      visible.value = true;
    };

    const onCancel = () => {
      visible.value = false;
    };
    return {
      onCancel,
      showModal,
      onChangeCategory,
      editModalVisible,
      onProjectSearch,
      projectType,
      onSwitchChange,
      onSorting,
      addModalVisible,
      selectedUserId,
      onClickAddUser,
      onClickEditUser,
      searchData,
      categoryActive,
      users,
      projectData,
      formState,
      path,
      handleDeleteUser,
      onUserSearch,
      handleEditUser,
      updateTableKey,
      handleAddUser,
      columns,
      visible,
    };
  },
};

export default UserManagement;
</script>
