<template>
  <sdCards>
    <div style="height=512px" class="spin" v-if="!selectedBpmlId">
        <a-empty description="Please select a BPML" />
    </div>
    <div v-else class="file-list">
      <h3 style="margin-bottom: 25px"> Video : </h3>
      <div v-if="videoAvailable" class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/mp4.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">{{videoFileName}}</span>
            <span class="file-content-action">
              <a @click="onClickVideoChange" to="#">Change</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <a @click="onClickVideoChange" to="#">
                <sdFeatherIcons type="edit" size="14" />
                Change
              </a>
              <a-popconfirm
                title="Are you sure delete this video?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteVideo"
                @cancel="cancel"
              >
                <a to="#">
                  <sdFeatherIcons type="trash-2" size="14" />
                  Delete
                </a>
              </a-popconfirm>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>
      <div v-else class="">
         <a-upload
            v-model:file-list="fileList"
            name="file"
            :beforeUpload="beforeVideoUpload"
            :headers="headers"
            @change="handleVideoChange"
          >
            <a-button>
              <sdFeatherIcons type="upload" style="color: #000; margin-right: 15px" /> Click to Upload
            </a-button>
          </a-upload>
      </div>

      <a-divider />

      <h3 style="margin-bottom: 25px"> EUT Video : </h3>
      <div v-if="eutAvailable" class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/mp4.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">{{eutFileName}}</span>
            <span class="file-content-action">
              <a @click="onClickEutChange" to="#">Change</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <a @click="onClickEutChange" to="#">
                <sdFeatherIcons type="edit" size="14" />
                Change
              </a>
              <a-popconfirm
                title="Are you sure delete this video?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteEut"
                @cancel="cancel"
              >
                <a to="#">
                  <sdFeatherIcons type="trash-2" size="14" />
                  Delete
                </a>
              </a-popconfirm>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>
      <div v-else class="">
         <a-upload
            v-model:file-list="fileList"
            name="file"
            :beforeUpload="beforeEutUpload"
            :headers="headers"
            @change="handleVideoChange"
          >
            <a-button>
              <sdFeatherIcons type="upload" style="color: #000; margin-right: 15px" /> Click to Upload
            </a-button>
          </a-upload>
      </div>

    </div>
  </sdCards>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import request from 'umi-request';
import { API_ENDPOINT } from '@/services/api/axiosConfig.js';
const BpmlFileListCard = defineComponent({
  name: 'BpmlFileListCard',
  setup() {
    const { state, dispatch } = useStore();
    const selectedBpmlId = computed(() => state.project.selectedBpmlId);
    const selectedBpml = computed(() => state.project.selectedBpml);
    const project = computed(() => state.project.singleData);
    const videoAvailable = ref(false)
    const videoFileName = ref("")
    const eutAvailable = ref(false)
    const eutFileName = ref("")
    const fileList = ref([]);

    const onClickVideoChange = () => {
      videoAvailable.value = false
    }

    const onClickEutChange = () => {
      eutAvailable.value = false
    }

    const checkVideoAvailability = () => {
      console.log('videoavailabilityCheck')
      for (const bpml of state.project.filteredBpmls) {
        if (bpml.bpmlCode === selectedBpml.value) {
          if (bpml.bpmlVideoFile) 
          {
            videoAvailable.value = true
            videoFileName.value = bpml.bpmlVideoName
          }
          else {
            videoAvailable.value = false
          }
          console.log(bpml.bpmlEutFile, 'eutfile')
          if (bpml.bpmlEutFile) 
          {
            eutAvailable.value = true
            eutFileName.value = bpml.bpmlEutName
          }
          else {
            eutAvailable.value = false
          }
        }
      }
    }
    
    checkVideoAvailability();

    watch(() => state.project.selectedBpml, function() {
      checkVideoAvailability();
    });

    watch(() => state.project.filteredBpmls, function() {
      checkVideoAvailability();
    });

    const deleteVideo = () => {
      const payload = { id: selectedBpmlId.value, data: { bpmlVideoFile: "", bpmlVideoName: "" }}
      dispatch('updateVideo', payload)
      message.success('Video Deleted')
    }

    const deleteEut = () => {
      const payload = { id: selectedBpmlId.value, data: { bpmlEutFile: "", bpmlEutName: "" }}
      dispatch('updateEut', payload)
      message.success('Video Deleted')
    }

    const beforeVideoUpload = file => {
      fileList.value = [...fileList.value, file];
      console.log(file.name, 'file')
      const formData = new FormData();
      // PRODUCTION WARNING
      const fileDir = `/uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`
      // formData.append("dir",`./uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`)
      // LOCAL
      // const fileDir = `/build/uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`
      formData.append("dir", `.${fileDir}`)
      //
      formData.append("filename", file.name)
      formData.append("file", file)
      request(`${API_ENDPOINT}/api/upload/projectFile`, {
        method: 'post',
        data: formData,
      }).then(() => {
        fileList.value = [];
        const payload = { id: selectedBpmlId.value, data: { bpmlVideoFile: `/PROJECT ID ${project.value._id}/${selectedBpml.value}/${file.name}`, bpmlVideoName: file.name }}
        dispatch('updateVideo', payload)
        checkVideoAvailability();
        console.log(payload, 'payload')
        message.success('upload successfully.');
      }).catch(() => {
        message.error('upload failed.');
      });
    }

    const beforeEutUpload = file => {
      fileList.value = [...fileList.value, file];
      console.log(file.name, 'file')
      const formData = new FormData();
      // PRODUCTION WARNING
      const fileDir = `/uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`
      // formData.append("dir",`./uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`)
      // LOCAL
      // const fileDir = `/build/uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`
      formData.append("dir", `.${fileDir}`)
      //
      formData.append("filename", file.name)
      formData.append("file", file)
      request(`${API_ENDPOINT}/api/upload/projectFile`, {
        method: 'post',
        data: formData,
      }).then(() => {
        fileList.value = [];
        const payload = { id: selectedBpmlId.value, data: { bpmlEutFile: `/PROJECT ID ${project.value._id}/${selectedBpml.value}/${file.name}`, bpmlEutName: file.name }}
        dispatch('updateEut', payload)
        checkVideoAvailability();
        console.log(payload, 'payload')
        message.success('upload successfully.');
      }).catch(() => {
        message.error('upload failed.');
      });
    }
    

    const handleVideoChange = info => {
      console.log(info)
          // if (info.file.status !== 'uploading') {
          //   console.log(info.file, info.fileList);
          // }
          // if (info.file.status === 'done') {
          //   message.success(`${info.file.name} file uploaded successfully`);
          // } else if (info.file.status === 'error') {
          //   message.error(`${info.file.name} file upload failed.`);
          // }
        };

    return {
      selectedBpmlId,
      videoAvailable,
      fileList,
      beforeVideoUpload,
      videoFileName,
      deleteVideo,
      onClickEutChange,
      eutAvailable,
      eutFileName,
      onClickVideoChange,
      beforeEutUpload,
      deleteEut,
      handleVideoChange,
      headers: {
        authorization: 'authorization-text',
      },
    }
  },
});

export default BpmlFileListCard;
</script>
