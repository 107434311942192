<template>
  <ProjectDetailsWrapper>
    <div style="backgroundColor: #F4F5F7; margin: 15px 0;">
        <h3 style="margin: 10px 45px;"> Assesments </h3>
    </div>
    <!-- <sdPageHeader style="margin: 0 !important; padding: 0 !important" v-if="project">
      <template #title>
        <h3> Assesments </h3>
      </template>
    </sdPageHeader> -->
    <Main>
      <!-- <div v-if="!project" class="spin">
        <a-spin />
      </div> -->
      <a-row :gutter="25">
        <a-col :xxl="8" :xl="8" :md="24" :xs="24">
          <BpmlSidebar>
          <sdCards headless>
            <div class="" style="margin: 10px 15px; display: flex; justify-content: space-around">
              <div style="width: 100%">
                <a-select
                  v-model:value="selectedModule"
                  placeholder="BPML Modules"
                  style="width: 100%"
                  @change="handleModuleChange"
                  :options="moduleOptions"
                ></a-select>
              </div>
              <!-- <div style="width: 100%" class="">
                <a-select
                  v-model:selectedKeys="selectedL2"
                  placeholder="All Sub Modules"
                  style="width: 100%"
                >
                <a-select-option v-for="L2 in bpmlL2s" :key="L2.bpmlL2" :value="L2.bpmlL2" @click="() => onMenuClick(L2.bpmlL2)">
                  <sdFeatherIcons type="inbox" size="14" style="margin-right: 5px;" /> {{L2.bpmlL2}}
                </a-select-option>
                </a-select>
              </div> -->
            </div>
            <div style="margin: 10px 15px">
              <a-input-search
                style="height: 45px"
                placeholder="Search BPML Code"
                @search="onBpmlSearch"
              />
            </div>

              <!-- <a-menu v-model:selectedKeys="selectedL2" mode="horizontal">
                <a-menu-item v-for="L2 in bpmlL2s" :key="L2.bpmlL2" :value="L2.bpmlL2" @click="() => onMenuClick(L2.bpmlL2)">
                  <sdFeatherIcons type="inbox" size="14" style="margin-right: 5px;" /> {{L2.bpmlL2}}
                </a-menu-item>
              </a-menu> -->
              <BpmlContent>
                <perfect-scrollbar
                style="max-height: 465px;"
                  :options="{
                    wheelSpeed: 1,
                    swipeEasing: true,
                    suppressScrollX: true,
                  }"
                >
                <div class="spin" v-if="bpmlLoading">
                  <a-spin />
                </div>
                <ul v-else>
                  <li v-for="bpml in bpmls" :key="bpml._id">
                    <ProjectCard>
                      <a href="#">
                      <sdCards :class="{ bpmlActive : active_bpml_el == bpml.bpmlCode }" @click="activate(bpml._id, bpml)">
                        <grid-card :class="{ bpmlActive : active_bpml_el == bpml._id }" :bpml="bpml" :projectId="projectId" :project="project" />
                      </sdCards>
                      </a>
                    </ProjectCard>
                  </li>
                </ul>
                </perfect-scrollbar>
              </BpmlContent>
          </sdCards>
          </BpmlSidebar>
        </a-col>
        <a-col :xxl="16" :xl="16" :md="24" :xs="24">
          <div class="about-project-wrapper">
            <sdCards style="height: 612px" title="Questions">
              <a-row :gutter="25">
                <a-col :xl="24">
                  <div class="about-content" style="display: flex; justify-content: space-between">
                    <div style="width: 100%" class="">
                      <nav style="margin-bottom: 20px">
                        <a-menu @click="onChangeLevel" v-model:selectedKeys="bodyMenu" mode="horizontal">
                          <a-menu-item :key="1" value="doc">
                            <span style="font-size: 16px;">Level 1</span>
                          </a-menu-item>
                          <a-menu-item :key="2" value="testing">
                            <span style="font-size: 16px;"> Level 2 </span>
                          </a-menu-item>
                        </a-menu>
                      </nav>
                    </div>
                    <!-- <sdButton @click="downloadTemplateExample" size="small" style="float: right; margin-right: 15px; margin-bottom: 10px;" type="primary">
                      <sdFeatherIcons type="download" style="margin-right: 5px" size="16" /> Download Template Example
                    </sdButton>
                    <a-upload
                      v-model:file-list="fileList"
                      name="file"
                      :beforeUpload="beforeUpload"
                      :headers="headers"
                      :show-upload-list="false"
                      @change="handleChange"
                    >
                    <sdButton @click="showModal" size="small" style="float: right; margin-right: 15px; margin-bottom: 10px;" type="success">
                      <sdFeatherIcons type="upload" style="margin-right: 5px" size="16" /> Questions Bulk Upload Excel 
                    </sdButton>
                    </a-upload> -->
                    <sdButton v-if="selectedBpmlId && user.role === 'Admin'" @click="showAddModal" size="small" style="float: right; margin-bottom: 10px;" outlined type="primary">
                        <sdFeatherIcons type="plus" size="14" />
                        Add Question
                      </sdButton>
                  </div>
                </a-col>
              <a-col :xl="24">
              <FaqWrapper>
                <Questions />
              </FaqWrapper>
              </a-col>
              </a-row>
            </sdCards>
          </div>
        </a-col>
      </a-row>
    </Main>
  </ProjectDetailsWrapper>
</template>
<script>
import { ProjectDetailsWrapper, BpmlSidebar, BpmlContent, ProjectCard } from './style';
import { Main } from '../styled';
import { FaqWrapper } from '../pages/style';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, defineComponent, defineAsyncComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Questions from './overview/Questions.vue'
import { API_ENDPOINT } from '@/services/api/axiosConfig.js';

const GridCard = defineAsyncComponent(() => import('./overview/GridCard.vue'));
const AssesmentDetails = defineComponent({
  name: 'AssesmentDetails',
  components: { ProjectDetailsWrapper, Main, FaqWrapper, GridCard, BpmlSidebar, BpmlContent, PerfectScrollbar, ProjectCard, Questions },
  setup() {
    const { state, dispatch } = useStore();
    const project = computed(() => state.project.singleData);
    const { params, matched } = useRoute();
    const fileList = ref([]);
    const { title, content } = computed(() => state.project.singleData[0]);
    const bpmls = computed(() => state.project.filteredBpmls)
    const user = computed(() => state.auth.currentAccount);
    const bpmlL2s = computed(() => state.project.bpmlL2s);
    const selectedModule = computed(() => state.project.selectedModule);
    const bpmlLoading = computed(() => state.project.bpmlLoading);
    const selectedLevel = computed(() => state.project.selectedLevel);
    const selectedBpmlId = computed(() => state.project.selectedBpmlId)

    let selectedL2 = ref(['']);
    let bodyMenu = ref([1]);
    const moduleVal = ref("FI");
    let active_bpml_el = ref('');
    const subModuleVal = ref("General Ledger");

    const downloadTemplateExample = () => {
      window.open(`${API_ENDPOINT}/public/templates/Template Excel Questions.xlsx`)
    }

    onMounted(() => dispatch('filterSinglePage', parseInt(params.id, 10)));
    onMounted(() => dispatch('fetchAssesment', params.id));
    onMounted(() => dispatch('fetchBpmlL2s'));
    onMounted(() => dispatch('fetchAssesmentBpmls', params.code));

    onUnmounted(() => { state.project.filteredBpmls = []; state.project.selectedBpml = null; state.project.selectedBpmlId = null; state.project.selectedLevel = [1] })

    const showAddModal = () => {
      state.project.addAssesmentModal = true
    }

    const onChangeLevel = (val) => {
      state.project.selectedLevel = val.keyPath
      console.log(state.project.selectedLevel, 'val')
    }

    const onBpmlSearch = (searchValue) => {
      state.project.selectedL2 = ""
      selectedL2.value = [""]
      dispatch('filterByBpml', searchValue)
    }

    const handleModuleChange = (val) => {
      state.project.selectedModule = val
      dispatch('filterByModules', val)
    }

    const onMenuClick = (item) => {
      console.log(item, 'item')
      state.project.selectedL2 = item
      dispatch('filterBpmlByL2', item)
    }

    const activate = (id, el) => {
        active_bpml_el.value = el;
        state.project.selectedBpmlObj = el
        state.project.selectedBpml = el.bpmlCode
        state.project.selectedBpmlId = id
        console.log(el, 'elbpml')
    }

    const beforeUpload = (file) => {
      // Check if the file is an Excel file (has .xlsx extension)
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        // If the file is not an Excel file, display an error message
        message.error('You can only upload Excel files!');
      }

      fileList.value = [...fileList.value, file];
      //LOCAL
      // const fileDir = `./build/uploads/templates/`
      // PRODUCTION WARNING
      const fileDir = `./uploads/templates/`
      const formData = new FormData();
      formData.append("filename", file.name)
      formData.append("dir", `.${fileDir}`)
      formData.append("file", file)
      // formData.append("assesment_id", params.id)
      dispatch('uploadExcelQuestions', formData)

      // Return true to allow the file to be uploaded
      // Return false to prevent the file from being uploaded
      // return isExcel;
    }

    const handleChange = async () => {
      // if (file.status === 'done') {
      //   // If the file has been successfully uploaded, display a success message
      //   message.success(`${file.name} file uploaded successfully.`);
      // } else if (file.status === 'error') {
      //   // If there was an error uploading the file, display an error message
      //   message.error(`${file.name} file upload failed.`);
      // }
    }

    const moduleOptions = ref([
      { value: '', label: 'All Modules' },
      { value: 'FI', label: 'FI' },
      { value: 'CO', label: 'CO' },
      { value: 'MM', label: 'MM' },
      { value: 'PM', label: 'PM' },
      { value: 'PP', label: 'PP' },
      { value: 'PS', label: 'PS' },
      { value: 'QM', label: 'QM' },
      { value: 'SD', label: 'SD' },
    ]);

    return {
      title,
      content,
      onMenuClick,
      bodyMenu,
      moduleVal,
      subModuleVal,
      selectedLevel,
      onChangeLevel,
      activate,
      project,
      selectedModule,
      active_bpml_el,
      showAddModal,
      handleChange,
      beforeUpload,
      fileList,
      headers: {
        authorization: 'authorization-text',
      },
      bpmls,
      params,
      handleModuleChange,
      selectedL2,
      bpmlLoading,
      onBpmlSearch,
      downloadTemplateExample,
      moduleOptions,
      user,
      matched,
      bpmlL2s,
      selectedBpmlId,
    };
  },
  methods: {
    async upload() {
      console.log('upload')
    },
  },
});

export default AssesmentDetails;
</script>
<style>
 .ps__rail-x,
.ps__rail-y {
  opacity: 0.6 !important;
}
</style>
