<template>
  <sdModal
    :type="modalType"
    title="Edit Project"
    :visible="visible"
    :onCancel="handleCancel"
  >
    <div class="project-modal">
      <BasicFormWrapper>
        <a-form
          :model="formState"
          :layout="formState.layout"
        >
          <a-form-item name="members" label="Project Members">
              <a-select
                v-model:value="newMembers"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="[...members].map((x) => ({ value: x.username }))"
                @change="handleMembersChange"
              ></a-select>
            </a-form-item>
          <!-- <a-form-item name="projectId" label="Project ID">
              <a-input-number
              v-model:value="formState.projectId"
              placeholder="Project ID"
              />
          </a-form-item> -->
          <a-form-item name="product" label="Product">
            <a-select v-model:value="formState.product" style="width: 100%">
              <a-select-option value="SAP">SAP</a-select-option>
              <a-select-option value="SERRALA">Serrala</a-select-option>
              <a-select-option value="IRIS">IRIS</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="company" label="Project Company">
            <a-input v-model:value="formState.company" placeholder="Project Company" />
          </a-form-item>
          <a-form-item name="PO" label="PO">
            <a-input
              v-model:value="formState.PO"
              placeholder="PO"
            />
          </a-form-item>
          <a-form-item name="industry" label="Industry">
            <a-select v-model:value="formState.industry" style="width: 100%">
              <a-select-option v-for="industry in industries" :key="industry._id" :value="industry.industry">
                {{ industry.industry + " (" + industry.bpmlTemplateCode + ")" }}
              </a-select-option>
              <!-- <a-select-option value="T_MFG">Manufacturing (T_MFG)</a-select-option>
              <a-select-option value="T_MNG">Mining (T_MNG)</a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item name="projectCode" label="Project Code">
            <a-input
              v-model:value="formState.projectCode"
              placeholder="Project Code (ex: CBA)"
            />
          </a-form-item>
          <a-form-item name="Category" label="Project Category">
            <a-select
                v-model:value="formState.category"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="categoryOptions"
              ></a-select>
            <!-- <a-checkbox-group
              v-model:value="formState.category"
              :options="categoryOptions"
            /> -->
          </a-form-item>
          <a-form-item name="status" label="Status">
            <a-select v-model:value="formState.status" style="width: 100%">
              <a-select-option value="Preparation">Preparation</a-select-option>
              <a-select-option value="Kick Off">Kick Off</a-select-option>
              <a-select-option value="Realization">Realization</a-select-option>
              <a-select-option value="GoLive">GoLive</a-select-option>
              <a-select-option value="Support">Support</a-select-option>
              <a-select-option value="Extension">Extension</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="year" label="Project Year">
            <a-input-number :rows="4" placeholder="Project Year" v-model:value="formState.year" />
          </a-form-item>
          <!-- <a-form-item name="videoLink" label="Video Link">
            <a-input
              v-model:value="formState.videoLink"
              placeholder="Video Link"
            />
          </a-form-item> -->
          <a-row :gutter="15">
            <a-col :md="12">
              <a-form-item name="startDate" label="Start Date">
                <a-date-picker
                  v-model:value="formState.startDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12">
              <a-form-item name="endDate" label="End Date">
                <a-date-picker
                  v-model:value="formState.endDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24">
              <a-form-item style="margin-top: 15px" name="progress" label="Progress">
                <a-slider v-model:value="formState.progress" :tip-formatter="formatter" :marks="marks">
                  <template #mark="{ label, point }">
                    <template v-if="point === 100">
                      <strong>{{ label }}</strong>
                    </template>
                    <template v-else>{{ label }}</template>
                  </template>
                </a-slider>
              </a-form-item>
            </a-col>
            <a-col :md="12">
              <a-form-item>
                <ProjectModalFooter>
                  <sdButton
                    size="default"
                    type="primary"
                    key="submit"
                    @click="handleOk"
                  >
                    Update Project
                  </sdButton>
                  <sdButton
                    size="default"
                    type="light"
                    key="back"
                    outlined
                    @click="handleCancel"
                  >
                    Cancel
                  </sdButton>
                </ProjectModalFooter>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>
</template>
<script>
import propTypes from "vue-types";
import { BasicFormWrapper } from "../../../view/styled";
import { ProjectModalFooter } from "../style";
import { reactive, ref, toRefs, defineComponent, computed, watch } from "vue";
import { useStore } from 'vuex';
const moment = require('moment');

const categoryOptions = [
  {
    label: "Implementation",
    value: "Implementation",
  },
  {
    label: "SAP Upgrade",
    value: "SAP Upgrade",
  },
  {
    label: "Change Request",
    value: "Change Request",
  },
  {
    label: "Roll Out",
    value: "Roll Out",
  },
];

const dateFormat = "MM/DD/YYYY";

const EditProject = defineComponent({
  name: "EditProject",
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const modalType = ref("primary");
    const checked = ref([]);
    const { onCancel } = toRefs(props);
    const formatter = (value) => {
      return `${value}%`;
    };
    dispatch("fetchUsers");
    dispatch("fetchIndustries");
    console.log(props.visible, 'visible')
    const marks = ref({
      0: '0%',
      50: '50%',
      80: '80%',
      100: {
        style: {
          color: '#20C997',
        },
        label: '100%',
      },
    });
    const members = computed(() => state.auth.users);
    const industries = computed(() => state.project.industries);
    const selectedProject = computed(() => state.project.selectedProject);
    let projDetail = {...selectedProject.value}
    const existingMembers = ref([]);
    const newMembers = ref([]);

    let formState = reactive({
      members: [],
      projectId: projDetail.projectId,
      PO: projDetail.PO,
      category: projDetail.categories,
      product: projDetail.product,
      industry: projDetail.industry,
      projectCode: projDetail.projectCode,
      startDate: projDetail.startDate,
      year: projDetail.year,
      endDate: projDetail.endDate,
      status: projDetail.status,
      progress: projDetail.progress,
      company: projDetail.company,
      videoLink: projDetail.videoLink,
      description: projDetail.description,
      layout: "vertical",
    })
    
    const fetchProjectDetail = () => {
      existingMembers.value = []
      newMembers.value = []
      for (const member of members.value) {
        if (member.projects.find(el => el.project_id === selectedProject.value._id)) {
          existingMembers.value.push(member.username)
          newMembers.value.push(member.username)
        }
      }

      console.log(existingMembers.value, 'existing members')

      projDetail = {...selectedProject.value}
      projDetail.categories = []
      if (projDetail.category && projDetail.category.length > 0) {
        for (const category of projDetail.category) {
          projDetail.categories.push(category.category)
        }
      }
      formState.projectId = projDetail.projectId,
      formState.PO = projDetail.PO,
      formState.category = projDetail.categories,
      formState.product = projDetail.product,
      formState.company = projDetail.company,
      formState.industry = projDetail.industry,
      formState.projectCode = projDetail.projectCode,
      formState.startDate = moment(projDetail.startDate),
      formState.year = projDetail.year,
      formState.endDate = moment(projDetail.endDate),
      formState.status = projDetail.status,
      formState.progress = projDetail.progress,
      formState.videoLink = projDetail.videoLink,
      formState.description = projDetail.description
      console.log(formState, 'projdetail')
    }

    watch(() => state.project.selectedProject, function() {
      fetchProjectDetail();
    });
  

    const handleMembersChange = (value) => {
      console.log(`selected ${value}`);
    };

    const handleOk = () => {
      const newProjectForm = {
        members: [],
        removeMembers: [],
        projectId: formState.projectId,
        PO: formState.PO,
        product: formState.product,
        startDate: moment(formState.startDate._d).format("YYYY-MM-DD"),
        year: 2022,
        category: [],
        projectCode: formState.projectCode,
        endDate: moment(formState.endDate._d).format("YYYY-MM-DD"),
        status: formState.status,
        industry: formState.industry,
        company: formState.company,
        progress: formState.progress,
        videoLink: formState.videoLink,
        description: formState.description,
      }
      for (const category of formState.category) {
        newProjectForm.category.push({
          category: category
        })
      }
      for (const member of newMembers.value) {
        if (!existingMembers.value.find(el => el === member)) {
          newProjectForm.members.push(member)
        }
      }
      console.log(newProjectForm.members, 'members')
      for (const member of existingMembers.value) {
        if (!newMembers.value.find(el => el === member)) {
          newProjectForm.removeMembers.push(member)  
        }
      }
      console.log(newProjectForm.removeMembers, 'removemembers')
      const payload = {
        id: selectedProject.value._id,
        data: newProjectForm
      }
      dispatch('editProject', payload);
      onCancel.value();
    };

    const handleCancel = () => {
      onCancel.value();
    };

    return {
      categoryOptions,
      value: ref([]),
      handleOk,
      newMembers,
      handleCancel,
      modalType,
      marks,
      industries,
      formatter,
      handleMembersChange,
      members,
      checked,
      dateFormat,
      selectedProject,
      existingMembers,
      formState,
    };
  },
});

export default EditProject;
</script>
