<template>
  <a-row :gutter="25">
    <a-col :xs="24">
      <ProjectList>
        <div class="table-responsive">
          <a-table v-if="assesmentType === true" :pagination="pagination" :dataSource="dataSource" :columns="columns" >
            <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <div style="margin-left: 55px;">
                <sdDropdown
                  class="wide-dropdwon"
                >
                  <template #overlay>
                    <div>
                      <router-link :to="`/assesments/detail/${record.key}/${record.industryCode}`">View</router-link>
                      <a v-if="user.role === 'Admin'" v-on:click="() => onClickEdit(record)">
                        <span>Edit</span>
                      </a>
                      <a-popconfirm
                        v-if="user.role === 'Admin'"
                        title="Are you sure delete this assesment?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="() => onClickDelete(record.key)"
                        @cancel="cancel"
                      >
                        <a>
                          <span>Delete</span>
                        </a>
                        <!-- <a href="#">Delete</a> -->
                      </a-popconfirm>
                    </div>
                  </template>
                  <a to="#">
                    <sdFeatherIcons type="more-horizontal" size="18" />
                  </a>
                </sdDropdown>
            </div>
            </template>
            <template v-if="column.key === 'category'">
              <a-select
                v-model:value="value"
              disabled
                mode="multiple"
                style="width: 100%; color: #000 !important;"
                @change="handleChange"
              >
                <a-select-option value="jack">Implementation</a-select-option>
                <a-select-option value="lucy">Kick Off</a-select-option>
              </a-select>
            </template>
            <template v-if="column.key === 'project'">
              <a-select
                v-model:value="valueProject"
                style="width: 100%; color: #000 !important;"
                @change="handleChange"
              >
                <a-select-option value="10001">10001</a-select-option>
                <a-select-option value="10011">10011</a-select-option>
              </a-select>
            </template>
          </template>
          </a-table>
          <a-table v-else :pagination="pagination" :dataSource="dataSource" :columns="templateColumns" >
            <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <div style="margin-left: 55px;">
                <sdDropdown
                  class="wide-dropdwon"
                >
                  <template #overlay>
                    <div>
                      <router-link :to="`/assesments/detail/${record.key}/${record.industryCode}`">View</router-link>
                      <a v-if="user.role === 'Admin'" v-on:click="() => onClickEdit(record)">
                        <span>Edit</span>
                      </a>
                      <a-popconfirm
                         v-if="user.role === 'Admin'"
                        title="Are you sure delete this assesment?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="() => onClickDelete(record.key)"
                        @cancel="cancel"
                      >
                        <a>
                          <span>Delete</span>
                        </a>
                        <!-- <a href="#">Delete</a> -->
                      </a-popconfirm>
                    </div>
                  </template>
                  <a to="#">
                    <sdFeatherIcons type="more-horizontal" size="18" />
                  </a>
                </sdDropdown>
            </div>
            </template>
            <template v-if="column.key === 'category'">
              <a-select
                v-model:value="value"
                mode="multiple"
                style="width: 100%; color: #000 !important;"
                @change="handleChange"
              >
                <a-select-option value="jack">Implementation</a-select-option>
                <a-select-option value="lucy">Kick Off</a-select-option>
              </a-select>
            </template>
            <template v-if="column.key === 'project'">
              <a-select
                v-model:value="valueProject"
                style="width: 100%; color: #000 !important;"
                @change="handleChange"
              >
                <a-select-option value="10001">10001</a-select-option>
                <a-select-option value="10011">10011</a-select-option>
              </a-select>
            </template>
          </template>
          </a-table>
        </div>
      </ProjectList>
      <assesment-edit :visible="assesmentEditVisible" :onCancel="onCancel" />
    </a-col>
    <!-- <a-col :xs="24" class="pb-30">
      <ProjectPagination v-if="projects.length">
        <a-pagination
          @change="onHandleChange"
          showSizeChanger
          @showSizeChange="onShowSizeChange"
          :pageSize="1"
          :defaultCurrent="1"
          :total="1"
        />
      </ProjectPagination>
    </a-col> -->
  </a-row>
</template>
<script>
const moment = require('moment');
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import AssesmentEdit from './AssesmentEdit.vue'
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
const columns = [
  {
    title: 'Assesment ID',
    dataIndex: 'assesmentIdSpan',
    key: 'assesmentIdSpan',
  },
  {
    title: 'Start Date ',
    dataIndex: 'startDateSpan',
    key: 'startDateSpan',
  },
  {
    title: 'Total Time',
    dataIndex: 'totalTimeSpan',
    key: 'totalTimeSpan',
  },
  {
    title: 'Company',
    dataIndex: 'companySpan',
    key: 'companySpan',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Status',
    dataIndex: 'statusSpan',
    key: 'statusSpan',
  },
  {
    title: 'Account Manager',
    dataIndex: 'accountManagerSpan',
    key: 'accountManagerSpan',
  },
  {
    title: 'Last Changes By',
    dataIndex: 'updatedBy',
    key: 'updatedBy',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
  },
];

const templateColumns = [
  {
    title: 'Assesment ID',
    dataIndex: 'assesmentIdSpan',
    key: 'assesmentIdSpan',
  },
  {
    title: 'Start Date ',
    dataIndex: 'startDateSpan',
    key: 'startDateSpan',
  },
  {
    title: 'Total Time',
    dataIndex: 'totalTimeSpan',
    key: 'totalTimeSpan',
  },
  {
    title: 'Industry',
    dataIndex: 'industrySpan',
    key: 'industrySpan',
  },
  {
    title: 'Status',
    dataIndex: 'statusSpan',
    key: 'statusSpan',
  },
  {
    title: 'Account Manager',
    dataIndex: 'accountManagerSpan',
    key: 'accountManagerSpan',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
  },
];

const AssesmentLists = {
  name: 'AssesmentLists',
  components: { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList, AssesmentEdit },
  setup() {
    const { state, dispatch } = useStore();
    const projects = computed(() => state.project.data);
    const assesments = computed(() => state.project.filteredAssesments);
    const assesmentType = computed(() => state.project.assesmentType);
    const current = ref(0);
    const pageSize = ref(0);
    const value = ref(['jack', 'lucy'])
    const valueProject = ref(['10001']);
    const user = computed(() => state.auth.currentAccount);
    const assesmentEditVisible = ref(false)

    // onMounted(() => dispatch('fetchAssesments'));

    const onShowSizeChange = (c, p) => {
      current.value = c;
      pageSize.value = p;
    };

    const onCancel = () => {
      assesmentEditVisible.value = false
    }

    const onClickDelete = (id) => {
      console.log(id, 'id')
      dispatch('removeAssesment', id)
    }

    const onHandleChange = (c, p) => {
      current.value = c;
      pageSize.value = p;
    };

    const onClickEdit = (record) => {
      assesmentEditVisible.value = true
      console.log(assesmentEditVisible.value)
      state.project.selectedAssesment = record
    }

    const dataSource = computed(
      () =>
        assesments.value.length &&
        assesments.value.map((value) => {
          const { assesmentId, startDate, project, status, accountManager, updatedBy, industry, _id, industryCode } = value;
          console.log(updatedBy, 'updatedBy')
          return {
            key: _id,
            _id,
            industryCode,
            startDate,
            project,
            status,
            accountManager,
            updatedBy: <span class="year">{updatedBy}</span>,
            industry,
            assesmentId,
            assesmentIdSpan: <router-link to={`/assesments/detail/${_id}/${industryCode}`}>{assesmentId}</router-link>,
            startDateSpan: <span class="year">{moment(startDate).format("DD MMM YYYY")}</span>,
            companySpan: <span class="year">{project.company}</span>,
            industrySpan: <span class="year">{industry}</span>,
            totalTimeSpan: <span class="year">{moment().diff(moment(startDate), 'days') + ' Days'}</span>,
            accountManagerSpan: <span class="year">{accountManager}</span>,
            statusSpan: <a-tag class={`inprogress`}>{status}</a-tag>,
          };
        }),
    );
    return {
      dataSource,
      onHandleChange,
      onShowSizeChange,
      columns,
      projects,
      onClickEdit,
      valueProject,
      user,
      assesmentType,
      pagination: {
        pageSize: 9,
      },
      templateColumns,
      value,
      onCancel,
      onClickDelete,
      assesmentEditVisible,
    };
  },
};

export default AssesmentLists;
</script>
