<template>
  <div class="">
    <div class="spin" v-if="!selectedBpml">
        <a-empty description="Please select a BPML" />
    </div>
    <div v-else class="">
      <div class="" style="display: flex; justify-content: space-between; width: 100%;">
        <div class="" style="display: flex;">
          <div class="" style="margin-right: 15px;">
            <label style="font-weight: bold" for="event"> Event </label><br>
            <a-select id="event"
              ref="select"
              style="width: 120px; margin-top: 15px;"
              v-model:value="testCategory"
              @change="onSelectEvent"
            >
              <a-select-option value="UT">UT</a-select-option>
              <a-select-option value="SIT">SIT</a-select-option>
              <a-select-option value="UAT">UAT</a-select-option>
            </a-select>
          </div>
          <div class="">
            <label style="font-weight: bold" for="case"> Case </label><br>
            <div class=""></div>
            <a-select id="case"
              ref="select"
              style="width: 120px; margin-top: 15px;"
              v-model:value="selectedCaseId"
              @change="onSelectCase"
            >
              <a-select-option v-for="bpmlCase in bpmlCases" :key="bpmlCase._id" :value="bpmlCase._id">{{bpmlCase.case}}</a-select-option>
            </a-select>
          </div>
        </div>
          <div class="" style="display: flex; justify-content: space-between">
            <!-- <sdButton size="small" class="btn-addFaq" style="margin-bottom: 10px !important; margin-right: 5px;z-index: 9999;" outlined type="primary">
                <sdFeatherIcons type="plus" size="14" />
              Add Counter
            </sdButton> -->
            <sdButton @click="showModal" size="small" class="btn-addPrimary" style="margin-bottom: 10px !important; margin-right: 15px; z-index: 1;" outlined type="primary">
                <sdFeatherIcons type="plus" size="14" />
              Add Case
            </sdButton>
            <!-- <a-upload
              v-model:file-list="fileList"
              name="file"
            :show-upload-list="false"
              :beforeUpload="beforeFileUpload"
              :headers="headers"
              @change="handleUploadChange"
            >
              <sdButton @click="showAddModal" size="small" class="btn-addSuccess" style="float: right; margin-bottom: 10px; margin-right: 15px" outlined type="primary">
                <sdFeatherIcons type="upload" size="14" style=" margin-right: 15px" /> Upload Case Excel Template
              </sdButton>
            </a-upload> -->
            <!-- <sdButton size="small" class="btn-addPrimary" @click="downloadTemplateExample" style="float: right; margin-bottom: 10px;" outlined type="primary">
                <sdFeatherIcons type="download" size="14" style=" margin-right: 15px" /> Download Excel Template Example
              </sdButton> -->
          </div>
      </div>
      <div class="" style="padding-top:10px;">
          <a-row>
          <!-- <a-col :span="24">
            <a-statistic title="Case" :value="`1 - General Ledger : Balance Sheet`" style="font-weight: 500; margin-bottom: 10px" />
          </a-col> -->
          <a-col :span="24">
            <a-statistic :value="`${selectedBpml} - ${selectedBpmlObj.bpmlL3}`" style="font-weight: 500; margin-bottom: 10px">
              
            </a-statistic>
          </a-col>
          <a-col :span="24">
            <span v-if="!selectedCase">
              Case Empty
            </span>
            <a-timeline v-else>
              <p><span style="font-weight: bold">Testing Number :</span> {{selectedCase}} dari {{bpmlCases.length}} - <span style="font-weight: bold"> {{selectedCaseObj.caseDescription}} </span></p>
              <p><span style="font-weight: bold">Testing Date :</span> {{moment(selectedCaseObj.testingDate).format('DD MMM YYYY')}}</p>
              <p><span style="font-weight: bold">Key Users :</span> <span v-for="user in selectedCaseObj.keyUsers" :key="user"> {{user}}, </span></p>
            </a-timeline>
          </a-col>
        </a-row>
        <sdButton v-if="selectedCase" @click="showTestModal" size="small" class="btn-addFaq" style="float: right; margin-bottom: 20px !important;z-index: 1; margin-right: 5px;" outlined type="primary">
          <sdFeatherIcons type="plus" size="14" />
          Add Test Script
        </sdButton>
        <sdButton v-if="selectedCase" @click="addCounter" size="small" class="btn-addFaq" style="float: right; margin-bottom: 20px !important;z-index: 1; margin-right: 5px;" outlined type="primary">
          <sdFeatherIcons type="plus" size="14" />
          Add Counter
        </sdButton>
        <sdButton v-if="selectedCase" @click="onClickEditCase" size="small" class="btn-addFaq" style="float: right; margin-bottom: 20px !important;z-index: 1; margin-right: 5px;" outlined type="primary">
          <sdFeatherIcons type="edit" size="13" style="margin-right: 5px" />
          Edit Case
        </sdButton>
        <a-table :pagination="pagination" :scroll="{ x: 2000 }" bordered :data-source="caseTests" :columns="columnTesting">
          <template #bodyCell="{ column, text, record }">
            <template v-if="['counter', 'CCodePlant', 'scenario', 'tcode', 'description', 'role', 'output', 'PICName', 'status', 'objective', 'updatedAt'].includes(column.dataIndex)">
              <div>
                <a-input-number
                  v-if="editableData[record.key] && column.key === 'counter'"
                  v-model:value="editableData[record.key][column.dataIndex]"
                  style="margin: -5px 0; width: 100%;"
                />
                <a-input
                  v-else-if="editableData[record.key] && (column.key === 'CCodePlant' || column.key === 'scenario' || column.key === 'tcode' || column.key === 'role' || column.key === 'output'|| column.key === 'PICName'|| column.key === 'status' )"
                  v-model:value="editableData[record.key][column.dataIndex]"
                  style="margin: -5px 0; width: 100%;"
                />
                <a-textarea
                  v-else-if="editableData[record.key] && (column.key === 'description' || column.key === 'scenario' || column.key === 'objective')"
                  v-model:value="editableData[record.key][column.dataIndex]"
                  style="margin: -5px 0; width: 100%;"
                />
                <template v-else-if="!editableData[record.key] && column.key === 'updatedAt'">
                  {{moment(record.updatedAt).format('DD MMM YYYY')}}
                </template>
                <template v-else-if="!editableData[record.key] && column.key === 'description'">
                  <ProjectListTitle>
                      <sdHeading as="h4">
                        {{selectedCaseObj.caseDescription}}
                      </sdHeading>
                      <p> {{text}} </p>
                  </ProjectListTitle>
                </template>
                <template v-else>
                  {{ text }}
                </template>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
              <div style="margin-right: 8px" class="editable-row-operations">
                <span v-if="editableData[record.key]">
                  <a @click="saveTable(record.key)">Save</a> |
                  <a-popconfirm title="Sure to cancel?" @confirm="cancelTable(record.key)">
                    <a>Cancel</a>
                  </a-popconfirm>
                </span>
                <span v-else>
                  <a  @click="editTable(record.key)">Edit</a> | 
                  <a-popconfirm title="Sure to delete?" @confirm="deleteTable(record._id)">
                    <a>Delete</a>
                  </a-popconfirm>
                </span>
              </div>
            </template>
          </template>
          <!-- <template #operation>
            <a-popconfirm
              v-if="dataSource.length"
              title="Sure to delete?"
            >
              <a href="javascript:;">Delete</a>
            </a-popconfirm>
          </template> -->
        </a-table>
         <!-- <sdButton @click="saveTable" size="small" class="btn-saveChanges" style="margin-top: 10px !important;z-index: 9999;" outlined type="warning">
          Save Changes
        </sdButton> -->
        <a-modal style="width: 75em" v-model:visible="visible" title="Add Case">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleOk">Add Case</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Case Description"
              name="caseDescription"
              :rules="[{ required: true, message: 'Please input case description!' }]"
            >
              <a-input v-model:value="formState.caseDescription" />
            </a-form-item>
            <a-form-item
              label="Testing Date"
              name="testingDate"
              :rules="[{ required: true, message: 'Please input testing date!' }]"
            >
              <a-date-picker
                  v-model:value="formState.testingDate"
                  style="width: 100%"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
            </a-form-item>
            <a-form-item
              label="Key Users"
              name="keyUsers"
              :rules="[{ required: true, message: 'Please input key user' }]"
            >
            <a-select
              v-model:value="formState.keyUsers"
              mode="tags"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="Automatic tokenization"
              :options="options"
              @change="handleChange"
            ></a-select>
            </a-form-item>
            <a-form-item
              label="CCode/Plant"
              name="caseDescription"
            >
              <a-textarea v-model:value="formState.CCodePlant" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal style="width: 75em" v-model:visible="editCaseVisible" title="Edit Case">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button @click="handleOkUpdate" key="submit" type="primary" :loading="caseLoading">Update Case</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Case Description"
              name="caseDescription"
              :rules="[{ required: true, message: 'Please input case description!' }]"
            >
              <a-input v-model:value="formState.caseDescription" />
            </a-form-item>
            <a-form-item
              label="Testing Date"
              name="testingDate"
              :rules="[{ required: true, message: 'Please input testing date!' }]"
            >
              <a-date-picker
                  v-model:value="formState.testingDate"
                  style="width: 100%"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
            </a-form-item>
            <a-form-item
              label="Key Users"
              name="keyUsers"
              :rules="[{ required: true, message: 'Please input key user' }]"
            >
            <a-select
              v-model:value="formState.keyUsers"
              mode="tags"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="Automatic tokenization"
              :options="options"
              @change="handleChange"
            ></a-select>
            </a-form-item>
            <a-form-item
              label="CCode/Plant"
              name="caseDescription"
            >
              <a-textarea v-model:value="formState.CCodePlant" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal style="width: 75em" v-model:visible="visibleTesting" title="Add Test / Counter">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="testLoading" @click="addTest">Add Test</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="testFormState"
            name="basic"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <!-- <a-form-item
              label="Counter"
              name="counter"
              :rules="[{ required: true, message: 'Please input counter!' }]"
            >
              <a-input-number style="width: 100%" v-model:value="testFormState.counter" />
            </a-form-item> -->
            <a-form-item
              label="Tcode"
              name="tcode"
              :rules="[{ required: true, message: 'Please input tcode!' }]"
            >
              <a-input v-model:value="testFormState.tcode" />
              <!-- <a-date-picker
                  v-model:value="formState.testingDate"
                  style="width: 100%"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                /> -->
            </a-form-item>
            <a-form-item
              label="Description"
              name="description"
              :rules="[{ required: true, message: 'Please input Description!' }]"
            >
              <a-textarea :rows="4" v-model:value="testFormState.description" />
            </a-form-item>
            <a-form-item
              label="Objective"
              name="objective"
              :rules="[{ required: true, message: 'Please input objective!' }]"
            >
              <a-textarea :rows="4" v-model:value="testFormState.objective" />
            </a-form-item>
            <a-form-item
              label="Role"
              name="role"
              :rules="[{ required: true, message: 'Please input role!' }]"
            >
              <a-input v-model:value="testFormState.role" />
            </a-form-item>
            <a-form-item
              label="PIC Name"
              name="PICName"
              :rules="[{ required: true, message: 'Please input PIC Name!' }]"
            >
              <a-input v-model:value="testFormState.PICName" />
            </a-form-item>
            <a-form-item
              label="Output"
              name="output"
            >
              <a-input v-model:value="testFormState.output" />
            </a-form-item>
            <a-form-item
              label="Status"
              name="status"
              :rules="[{ required: true, message: 'Please input status!' }]"
            >
              <a-input v-model:value="testFormState.status" />
            </a-form-item>
            <!-- <a-form-item
              label="Scenario"
              name="scenario"
              :rules="[{ required: true, message: 'Please input scenario!' }]"
            >
              <a-input v-model:value="testFormState.scenario" />
            </a-form-item> -->
          </a-form>
        </a-modal>
      </div>
    </div>

  </div>
</template>

<script>
import { computed, ref, reactive, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { ProjectListTitle } from '../style';
import moment from 'moment'
import { API_ENDPOINT } from '@/services/api/axiosConfig.js';
export default {
  components: {ProjectListTitle},
  setup() {
    const { state, dispatch } = useStore();
    const selectedBpml = computed(() => state.project.selectedBpml);
    const selectedBpmlId = computed(() => state.project.selectedBpmlId);
    const bpmlCases = computed(() => state.project.bpmlCases);
    const selectedCase = computed(() => state.project.selectedCase);
    const selectedCaseId = computed(() => state.project.selectedCaseId);
    const selectedCaseObj = computed(() => state.project.selectedCaseObj);
    const bpmls = computed(() => state.project.filteredBpmls)
    const caseTests = computed(() => state.project.bpmlTests);
    const visible = ref(false);
    const selectedBpmlObj = ref({});
    const visibleTesting = ref(false);
    const editCaseVisible = ref(false);
    const caseLoading = ref(false);
    const testLoading = ref(false);
    const modalFormRef = ref();
    const testCategory = computed(() => state.project.testCategory);
    const fileList = ref([]);

    onMounted(() => { 
      for (const bpml of bpmls.value) {
        if (bpml._id === selectedBpmlId.value) {
          selectedBpmlObj.value = bpml
        }
      }
     })

     const downloadTemplateExample = () => {
      window.open(`${API_ENDPOINT}/public/templates/Script Test Template.xlsx`)
    }

     const handleUploadChange = info => {
        console.log(info)
          // if (info.file.status !== 'uploading') {
          //   console.log(info.file, info.fileList);
          // }
          // if (info.file.status === 'done') {
          //   message.success(`${info.file.name} file uploaded successfully`);
          // } else if (info.file.status === 'error') {
          //   message.error(`${info.file.name} file upload failed.`);
          // }
      };

     const beforeFileUpload = file => {
      fileList.value = [...fileList.value, file];
      console.log(file.name, 'file')
      const formData = new FormData();
      // PRODUCTION WARNING
      const fileDir = `/uploads/templates/${selectedBpmlId.value}`
      // LOCAL
      // const fileDir = `/build/uploads/templates/${selectedBpmlId.value}`
      formData.append("dir", `.${fileDir}`)
      formData.append("filesize", (file.size / 1024).toFixed())
      //
      formData.append("filename", file.name)
      formData.append("file", file)
      formData.append("bpml_id", selectedBpmlId.value)
      const payload = {
        currentEvent: testCategory.value,
        formData,
      }
      dispatch('uploadCaseTemplate', payload)

      return true
    }

    const dataTesting = [
        {
          projectId: 100001,
          case: 1,
          counter: 1,
          bpmlCode: selectedBpml.value,
          tCode: 'FS00',
          description: 'Deskripsi',
          scenario: 'Perlu diulang',
          role: 'Accounting',
          output: '1000001',
        },
        {
          projectId: 100001,
          case: 1,
          counter: 1,
          bpmlCode: selectedBpml.value,
          tCode: 'S_ALR_87012328',
          description: 'Deskripsi',
          scenario: 'Perlu diulang',
          role: 'Accounting',
          output: '1000002',
        },
        //  {
        //   projectId: 100001,
        //   case: 1,
        //   counter: 2,
        //   bpmlCode: selectedBpml.value,
        //   tCode: 'FS00',
        //   description: 'Deskripsi',
        //   scenario: 'Perlu diulang',
        //   role: 'Accounting',
        //   output: '1000001',
        // },
        // {
        //   projectId: 100001,
        //   case: 1,
        //   counter: 2,
        //   bpmlCode: selectedBpml.value,
        //   tCode: 'S_ALR_87012328',
        //   description: 'Deskripsi',
        //   scenario: 'Perlu diulang',
        //   role: 'Accounting',
        //   output: '1000002',
        // },
    ]
    const columnTesting = [
        {
          title: 'Operation',
          key: 'operation',
          dataIndex: 'operation',
          width: 150,
          fixed: 'left',
        },
        {
          title: 'Counter',
          dataIndex: 'counter',
          key: 'counter',
          sorter: (a, b) => a.counter - b.counter,
          filters: [{
              text: 1,
              value: 1,
              }, {
                text: 2,
                value: 2,
            }],
            onFilter: (value, record) => record.counter === value,
        },
        {
          title: 'TCode',
          dataIndex: 'tcode',
          key: 'tcode',
          filters: [{
            text: 'FS00',
            value: 'FS00',
            }, {
              text: 'S_ALR_87012328',
              value: 'S_ALR_87012328',
          }],
          onFilter: (value, record) => record.tcode.indexOf(value) === 0,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
          key: 'description',
          width: 300,
        },
        {
          title: 'Objective',
          dataIndex: 'objective',
          scopedSlots: { customRender: 'objective' },
          key: 'objective',
          width: 400,
        },
        {
          title: 'Role',
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: 'Output',
          dataIndex: 'output',
          key: 'output',
        },
        {
          title: 'PIC Name',
          dataIndex: 'PICName',
          key: 'PICName',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
          {
            title: 'Last Updated',
            scopedSlots: { customRender: 'updatedAt' },
            dataIndex: 'updatedAt',
            key: 'updatedAt',
          },
        // {
        //   title: 'Action',
        //   dataIndex: 'action',
        //   key: 'output',
        //   scopedSlots: { customRender: 'action' },
        // },
    ]

    const onClickEditCase = () => {
      formState.caseDescription = selectedCaseObj.value.caseDescription
      formState.CCodePlant = selectedCaseObj.value.CCodePlant
      formState.keyUsers = selectedCaseObj.value.keyUsers
      formState.testingDate = moment(selectedCaseObj.value.testingDate)
      showEditCaseModal();
    }

    const fetchTestsByBpmlCase = () => {
      dispatch('fetchTestsByBpmlCase', selectedCaseId.value)
    }

    const editableData = reactive({});
    const editTable = key => {
      const targetRow = caseTests.value.filter(item => key === item.key)[0];
      editableData[key] = {...targetRow}
      console.log(editableData[key], 'editabledatakey')
    };
    const deleteTable = id => {
      const targetData = caseTests.value.filter(item => item._id === id)[0];
      const payload = { id: targetData._id, data: targetData[0] }
      dispatch('deleteTestInCase', payload)
    }
    const saveTable = key => {
      const newData = {}
      Object.assign(newData, editableData[key])
      console.log(newData, 'newadata')
      const payload = { id: newData._id, data: newData }
      dispatch('updateTestsByBpmlCase', payload)
      // Object.assign(caseTests.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const cancelTable = key => {
      delete editableData[key];
      console.log(caseTests.value, 'casetests')
    };
    
    const getCases = () => {
      const payload = {
        bpml_id: selectedBpmlId.value,
        event: testCategory.value
      }
      dispatch('fetchCasesByBpml', payload)
    }

    onMounted(() => { getCases(); });

    watch (() => state.project.selectedCaseId, function () {
      fetchTestsByBpmlCase();
    });


    watch(() => state.project.selectedBpmlId, function() {
      // state.project.selectedCase = null
      // state.project.selectedCaseId = null
      // state.project.selectedCaseObj = {}
      getCases()
      });

    const showModal = () => {
      visible.value = true;
    };

    const showEditCaseModal = () => {
      editCaseVisible.value = true;
    };

    const showTestModal = () => {
      visibleTesting.value = true;

    }

    const options = ref([{
      value: 'Tesseract',
      label: 'Tesseract',
    }]);

    const addCounter = () => {
      const tests = caseTests.value.filter(el => el.counter === 1);
      console.log(tests, 'tests')
      const biggestCounter = Math.max(...caseTests.value.map(object => object.counter));
      for (const test of tests) {
        const payload = {...test}
        delete payload._id
        payload.counter = biggestCounter + 1
        dispatch('createTestByBpmlCase', payload);
      }
    }

    const addTest = () => {
      modalFormRef.value.validateFields().then(() => {
        testLoading.value = true;
        const newTest = {
          counter: 1,
          description: testFormState.description,
          role: testFormState.role,
          PICName: testFormState.PICName,
          output: testFormState.output,
          tcode: testFormState.tcode,
          status: testFormState.status,
          objective: testFormState.objective,
          bpmlCase_id: selectedCaseId.value,
        }
        console.log(newTest, 'newtest')
        dispatch('createTestByBpmlCase', newTest);
        setTimeout(() => {
          testLoading.value = false;
          visibleTesting.value = false;
        }, 2000);
      })
    }

    const handleOk = () => {
      modalFormRef.value.validateFields().then(() => {
        caseLoading.value = true;
        console.log("ok")
        const newCase = {
          bpml_id: formState.bpml_id,
          case: bpmlCases.value.length + 1,
          caseDescription: formState.caseDescription,
          keyUsers: [],
          event: testCategory.value,
          CCodePlant: formState.CCodePlant,
          scenario: formState.scenario,
          testingDate: moment(formState.testingDate._d).format("MM-DD-YYYY"),
        }
        formState.keyUsers.forEach(x => newCase.keyUsers.push(x))
        console.log(newCase, 'kb')
        dispatch('createCaseByBpml', newCase)
        setTimeout(() => {
          caseLoading.value = false;
          visible.value = false;
        }, 2000);
      });
    };

    const handleOkUpdate = () => {
      modalFormRef.value.validateFields().then(() => {
        caseLoading.value = true;
        console.log("ok")
        const newCase = {
          bpml_id: formState.bpml_id,
          caseDescription: formState.caseDescription,
          keyUsers: [],
          event: testCategory.value,
          CCodePlant: formState.CCodePlant,
          testingDate: moment(formState.testingDate._d).format("MM-DD-YYYY"),
        }
        formState.keyUsers.forEach(x => newCase.keyUsers.push(x))
        console.log(newCase, 'kb')
        dispatch('editCase', { id: selectedCaseObj.value._id, data: newCase})
        setTimeout(() => {
          caseLoading.value = false;
          visible.value = false;
        }, 2000);
      });
    };

    const onSelectEvent = (e) => {
      console.log(e, 'e')
      // state.project.selectedCase = null
      // state.project.selectedCaseId = null
      // state.project.selectedCaseObj = {}
      state.project.testCategory = e
      getCases()
    }

    const onSelectCase = (val) => {
      const foundCase = bpmlCases.value.find(x => x._id === val)
      if (foundCase) {
        state.project.selectedCase = foundCase.case;
        state.project.selectedCaseId = val;
        state.project.selectedCaseObj = foundCase
        console.log(state.project.selectedCaseObj, 'selectedCaseObj')
      }
      console.log(foundCase.case, 'casenumber')
      console.log(selectedCaseId.value, 'casenumberId')
    }

    const handleCancel = () => {
      visible.value = false;
      visibleTesting.value = false;
      editCaseVisible.value = false;
    };

    const formState = reactive({
      caseDescription: null,
      bpml_id: selectedBpmlId,
      keyUsers: [],
      event: testCategory.value,
      testingDate: null,
      CCodePlant: null,
    });

    const testFormState = reactive({
      counter: null,
      description: null,
      role: null,
      tcode: null,
      PICName: null,
      objective: null,
      output: null,
      status: null,
      scenario: null,
    });

    return {
      dataTesting,
      testCategory,
      addTest,
      testFormState,
      onClickEditCase,
      downloadTemplateExample,
      editCaseVisible,
      editableData,
      handleUploadChange,
      addCounter,
      editTable,
      showEditCaseModal,
      selectedBpmlObj,
      saveTable,
      cancelTable,
      testLoading,
      handleOk,
      deleteTable,
      selectedBpml,
      selectedCase,
      caseTests,
      beforeFileUpload,
      onSelectCase,
      selectedCaseId,
      showTestModal,
      pagination: {
        pageSize: 9,
      },
      onSelectEvent,
      showModal,
      visible,
      options,
      caseLoading,
      modalFormRef,
      handleCancel,
      columnTesting,
      visibleTesting,
      formState,
      bpmlCases,
      moment,
      handleOkUpdate,
      selectedCaseObj,
      dateFormat: "MM/DD/YYYY"
    }
  }
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>