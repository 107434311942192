<template>
  <sdModal
    :type="modalType"
    title="Create Project"
    :visible="visible"
    :onCancel="handleCancel"
  >
    <div class="project-modal">
      <BasicFormWrapper>
        <a-form
          :model="formState"
          :layout="formState.layout"
        >
          <a-form-item v-if="projectType" name="members" label="Project Members">
              <a-select
                v-model:value="value"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="[...members].map((x) => ({ value: x.username }))"
                @change="handleMembersChange"
              ></a-select>
            </a-form-item>
          <!-- <a-form-item name="projectId" label="Project ID">
              <a-input-number
              v-model:value="formState.projectId"
              placeholder="Project ID"
              />
          </a-form-item> -->
          <a-form-item name="company" label="Project Assesment">
            <a-select show-search v-model:value="formState.company" style="width: 100%">
              <a-select-option @click="() => handleSelectCompany(assesment._id, assesment.project.company ? assesment.project.company : assesment.industry )" v-for="assesment in assesmentList" :key="assesment.industry" :value="assesment.industry">{{assesment.assesmentId}} - {{assesment.project.company ? assesment.project.company : assesment.industry}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="projectType" name="product" label="Product">
            <a-select v-model:value="formState.product" style="width: 100%">
              <a-select-option value="SAP">SAP</a-select-option>
              <a-select-option value="SERRALA">Serrala</a-select-option>
              <a-select-option value="IRIS">IRIS</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="projectType" name="PO" label="PO">
            <a-input
              v-model:value="formState.PO"
              placeholder="PO"
            />
          </a-form-item>
          <a-form-item v-if="!projectType" name="industry" label="Industry">
            <a-select v-model:value="formState.industry" style="width: 100%">
              <a-select-option v-for="industry in industries" :key="industry._id" :value="industry.industry">
                {{ industry.industry + " (" + industry.bpmlTemplateCode + ")" }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item v-if="projectType" name="projectCode" label="Project Code">
            <a-input
              v-model:value="formState.projectCode"
              placeholder="Project Code (ex: CBA)"
            />
          </a-form-item> -->
          <a-form-item v-if="projectType" name="Category" label="Project Category">
            <a-select
                v-model:value="formState.category"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="categoryOptions"
              ></a-select>
            <!-- <a-checkbox-group
              v-model:value="formState.category"
              :options="categoryOptions"
            /> -->
          </a-form-item>
          <a-form-item v-if="projectType" name="status" label="Status">
            <a-select v-model:value="formState.status" style="width: 100%">
              <a-select-option value="Preparation">Preparation</a-select-option>
              <a-select-option value="Kick Off">Kick Off</a-select-option>
              <a-select-option value="Realization">Realization</a-select-option>
              <a-select-option value="GoLive">GoLive</a-select-option>
              <a-select-option value="Support">Support</a-select-option>
              <a-select-option value="Extension">Extension</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="projectType" name="year" label="Project Year">
            <a-input-number :rows="4" placeholder="Project Year" v-model:value="formState.year" />
          </a-form-item>
          <!-- <a-form-item name="videoLink" label="Video Link">
            <a-input
              v-model:value="formState.videoLink"
              placeholder="Video Link"
            />
          </a-form-item> -->
          <a-row :gutter="15">
            <a-col :md="12">
              <a-form-item v-if="projectType" name="startDate" label="Start Date">
                <a-date-picker
                  v-model:value="formState.startDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12">
              <a-form-item v-if="projectType" name="endDate" label="End Date">
                <a-date-picker
                  v-model:value="formState.endDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24">
              <a-form-item v-if="projectType" style="margin-top: 15px" name="progress" label="Progress">
                <a-slider v-model:value="formState.progress" :tip-formatter="formatter" :marks="marks">
                  <template #mark="{ label, point }">
                    <template v-if="point === 100">
                      <strong>{{ label }}</strong>
                    </template>
                    <template v-else>{{ label }}</template>
                  </template>
                </a-slider>
              </a-form-item>
            </a-col>
            <a-col :md="12">
              <a-form-item>
                <ProjectModalFooter>
                  <sdButton
                    size="default"
                    type="primary"
                    key="submit"
                    @click="handleOk"
                  >
                    Add New Project
                  </sdButton>
                  <sdButton
                    size="default"
                    type="light"
                    key="back"
                    outlined
                    @click="handleCancel"
                  >
                    Cancel
                  </sdButton>
                </ProjectModalFooter>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>
</template>
<script>
import propTypes from "vue-types";
import { BasicFormWrapper } from "../../../view/styled";
import { ProjectModalFooter } from "../style";
import { reactive, ref, toRefs, defineComponent, computed, watch } from "vue";
import { useStore } from 'vuex';
const moment = require('moment');

const categoryOptions = [
  {
    label: "Implementation",
    value: "Implementation",
  },
  {
    label: "SAP Upgrade",
    value: "SAP Upgrade",
  },
  {
    label: "Change Request",
    value: "Change Request",
  },
  {
    label: "Roll Out",
    value: "Roll Out",
  },
];

const dateFormat = "MM/DD/YYYY";

const CreateProject = defineComponent({
  name: "CreateProject",
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const modalType = ref("primary");
    const checked = ref([]);
    const { onCancel } = toRefs(props);
    const formatter = (value) => {
      return `${value}%`;
    };
    dispatch("fetchUsers");
    dispatch("fetchAssesmentsQuery", state.project.projectType === true ? false : true);
    dispatch("fetchIndustries");
    watch (() => state.project.projectType, function () {
      dispatch("fetchAssesmentsQuery", state.project.projectType === true ? false : true);
    });
    const marks = ref({
      0: '0%',
      50: '50%',
      80: '80%',
      100: {
        style: {
          color: '#20C997',
        },
        label: '100%',
      },
    });
    const members = computed(() => state.auth.users);
    const industries = computed(() => state.project.industries);
    const assesmentList = computed(() => state.project.assesmentList);
    const projectType = computed(() => state.project.projectType);
    const user = computed(() => state.auth.currentAccount);
    const formState = reactive({
      projectId: "",
      PO: "",
      category: ["Implementation"],
      product: "SAP",
      members: [],
      industry: "Manufacturing",
      company: null,
      projectCode: "",
      startDate: "",
      year: 2022,
      endDate: "",
      status: "Preparation",
      progress: 0,
      videoLink: "",
      assesment: "",
      description: "",
      layout: "vertical",
    });

    const handleMembersChange = (value) => {
      console.log(`selected ${value}`);
      formState.members = value
      // formState.members.split(',');
      // console.log(formState.members)
    };

    const handleSelectCompany = (assesmentId, company) => {
      console.log(assesmentId, company)
      console.log(projectType.value)
      formState.assesment = assesmentId
      formState.company = company
    }

    const handleOk = () => {
      const newProjectForm = {
        assesment: formState.assesment,
        projectId: formState.projectId,
        PO: formState.PO,
        product: formState.product,
        startDate: moment(formState.startDate._d).format("YYYY-MM-DD"),
        year: 2022,
        category: [],
        members: formState.members,
        company: formState.company,
        projectCode: projectType.value === true ? formState.projectCode : formState.industry,
        endDate: moment(formState.endDate._d).format("YYYY-MM-DD"),
        status: formState.status,
        industry: formState.industry,
        progress: formState.progress,
        videoLink: formState.videoLink,
        description: formState.description,
        isTemplate: projectType.value === true ? false : true
      }
      for (const category of formState.category) {
        newProjectForm.category.push({
          category: category
        })
      }
      newProjectForm.members.push(user.value.username)
      console.log(newProjectForm, 'newProjectform')
      
      if (!projectType.value) {
        dispatch('insertProject', newProjectForm);
      } else {
        dispatch('insertProjectNonTemplate', newProjectForm)
      }
      formState.projectId = "",
      formState.PO= "",
      formState.category= ["Implementation"],
      formState.product= "SAP",
      formState.members= [],
      formState.industry= "Manufacturing",
      formState.company= null,
      formState.projectCode= "",
      formState.startDate= "",
      formState.year= 2022,
      formState.endDate= "",
      formState.status= "Preparation",
      formState.progress= 0,
      formState.videoLink= "",
      formState.assesment= "",
      formState.description= "",
      formState.layout= "vertical",
      onCancel.value();
    };

    const handleCancel = () => {
      onCancel.value();
    };

    return {
      categoryOptions,
      value: ref([]),
      handleOk,
      handleCancel,
      modalType,
      marks,
      handleSelectCompany,
      industries,
      assesmentList,
      formatter,
      handleMembersChange,
      members,
      checked,
      dateFormat,
      formState,
      projectType,
    };
  },
});

export default CreateProject;
</script>
