<template>
  <sdPageHeader title="Projects">
    <template v-slot:subTitle>
      <span class="" style="margin-left: 23px">{{projectData.length}} Running Projects </span>
    </template>
    <template v-slot:buttons>
      <!-- <sdButton @click="showModal" key="1" type="success">
        <sdFeatherIcons type="upload" style="margin-right: 10px" size="16" /> Import Sharepoint Files
      </sdButton> -->
      <sdButton v-if="user.role === 'Admin'" @click="showModal" key="1" type="primary">
        <sdFeatherIcons type="plus" size="16" /> Create Project
      </sdButton>
    </template>
  </sdPageHeader>

  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <a-row :gutter="25">
          <a-col :xl="21" :lg="21" :md="21" :sm="24" :xs="24">
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-search">
                  <sdAutoComplete @change="onProjectSearch" :dataSource="searchData" placeholder="Search Projects By Company" patterns />
                </div>
              </div>
            </ProjectSorting>
          </a-col>
          <a-col :xl="3" :lg="3" :md="3" :sm="24" :xs="24">
          <div class="">
              <span> Project Type : </span>
              <a-switch @change="onSwitchChange" style="margin-left: 15px" v-model:checked="projectType" checked-children="Project" un-checked-children="Template" />
            </div>
          </a-col>
        </a-row>
        <div>
          <ProjectLists />
          <!-- <router-view name="child"></router-view> -->
        </div>
      </a-col>
    </a-row>
    <project-create :onCancel="onCancel" :visible="visible" />
  </Main>
</template>
<script>
import { ProjectHeader, ProjectSorting } from './style';
import ProjectLists from './overview/List.vue';
import ProjectCreate from './overview/ProjectCreate.vue';
import { Main } from '../styled';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const Project = {
  name: 'Project',
  components: { ProjectHeader, ProjectSorting, Main, ProjectLists, ProjectCreate },
  setup() {
    const { state, dispatch } = useStore();
    const searchData = computed(() => state.headerSearchData);
    const projectData = computed(() => state.project.projectData);
    const { path } = useRoute().matched[1];
    const projectType = computed(() => state.project.projectType);
    const user = computed(() => state.auth.currentAccount);
    const visible = ref(false);
    const categoryActive = ref('all');

    const onSorting = (selectedItems) => {
      dispatch('sortingProjectByCategory', selectedItems);
    };

    console.log(state.auth.currentAccount, 'currentAccount Mounted')

    const onSwitchChange = (val) => {
      console.log(val, 'val')
      state.project.projectListLoading = true
      const payload = {
        isTemplate: !val,
        userId: user.value._id
      }
      console.log(state.auth.currentAccount, 'currentaccoutn on switch')
      if (user.value.role === 'Admin') {
          dispatch('fetchProjectsQueryAdmin', payload)
        } else {          
          console.log('role non admin')
          dispatch('fetchProjectsQuery', payload)
      }
      state.project.projectType = val
    }

    const onChangeCategory = (value) => {
      categoryActive.value = value;
      dispatch('filterProjectByStatus', value);
    };

    const onProjectSearch = (searchValue) => {
      console.log(searchValue, 'searchvalue')
      dispatch('projectSearchAction', searchValue)
    }
    

    const showModal = () => {
      visible.value = true;
    };

    const onCancel = () => {
      visible.value = false;
    };
    return {
      onCancel,
      showModal,
      onChangeCategory,
      onProjectSearch,
      projectType,
      onSwitchChange,
      onSorting,
      searchData,
      categoryActive,
      user,
      projectData,
      path,
      visible,
    };
  },
};

export default Project;
</script>
