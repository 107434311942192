<template>
  <sdPageHeader title="BPML Master Data">
    <template v-slot:subTitle>
      <!-- <span class="" style="margin-left: 23px">{{projectData.length}} R </span> -->
    </template>
    <template v-slot:buttons>
      <a-upload
        key="4"
        v-model:file-list="fileListBpml"
        name="file"
        :beforeUpload="beforeUploadBpml"
        :headers="headers"
        :show-upload-list="false"
        @change="handleChange"
      >
        <!-- <sdButton @click="showModal" size="small" style="float: right; margin-right: 15px; margin-bottom: 10px;" type="success">
              <sdFeatherIcons type="upload" style="margin-right: 5px" size="16" /> Uplaod BPML Template Excel
            </sdButton> -->
        <sdButton
          @click="showModal"
          size="small"
          style=""
          outlined
          type="primary"
        >
          <sdFeatherIcons type="upload" style="margin-right: 5px" size="14" />
          <span>Upload Bulk BPML</span>
        </sdButton>
      </a-upload>
      <a-upload
        key="3"
        v-model:file-list="fileListQuestions"
        name="file"
        :beforeUpload="beforeUploadQuestions"
        :headers="headers"
        :show-upload-list="false"
        @change="handleChange"
      >
        <sdButton
          @click="showModal"
          size="small"
          style=""
          outlined
          type="primary"
        >
          <sdFeatherIcons type="upload" style="margin-right: 5px" size="14" />
          Upload Bulk Questions
        </sdButton>
      </a-upload>
      <a-upload
        key="2"
        v-model:file-list="fileList"
        name="file"
        :show-upload-list="false"
        :beforeUpload="beforeFileUpload"
        :headers="headers"
        @change="handleUploadChange"
      >
        <sdButton key="1" size="small" outlined type="primary">
          <sdFeatherIcons type="upload" size="14" style="margin-right: 5px" />
          Upload Bulk Script Tests
        </sdButton>
      </a-upload>
      <sdButton @click="onClickAddUser" size="small" key="2" type="primary">
        <sdFeatherIcons type="plus" size="16" /> Add Industry
      </sdButton>
    </template>
  </sdPageHeader>

  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <div class="" style="display: flex; justify-content: space-between">
          <ProjectSorting>
            <div class="project-sort-bar">
              <div class="project-sort-search">
                <sdAutoComplete
                  @change="onIndustrySearch"
                  :dataSource="onIndustrySearch"
                  placeholder="Search By Industry"
                  patterns
                />
              </div>
            </div>
          </ProjectSorting>
          <!-- <div class="">
            <span> Project Type : </span>
            <a-switch @change="onSwitchChange" style="margin-left: 15px" v-model:checked="projectType" checked-children="Project" un-checked-children="Template" />
          </div> -->
        </div>
        <div :key="updateTableKey">
          <a-table :dataSource="industries" :columns="columns">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'role'">
                <a-tag v-if="text === 'Customer'" color="success">{{
                  text
                }}</a-tag>
                <a-tag v-if="text === 'Consultant'" color="warning">{{
                  text
                }}</a-tag>
                <a-tag v-if="text === 'Admin'" color="processing">{{
                  text
                }}</a-tag>
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <div style="margin-right: 8px" class="editable-row-operations">
                  <span>
                    <router-link
                      :to="`/master-data/detail/${record._id}/${record.bpmlTemplateCode}`"
                      >View</router-link
                    >
                    | <a @click="onClickEditIndustry(record)">Edit</a> |
                    <a-popconfirm
                      title="Sure to delete?"
                      @confirm="handleDeleteIndustry(record._id)"
                    >
                      <a>Delete</a>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </template>
          </a-table>
          <a-modal v-model:visible="addModalVisible" title="Add Industry">
            <template #footer>
              <a-button
                key="back"
                @click="
                  () => {
                    addModalVisible = false;
                  }
                "
                >Return</a-button
              >
              <a-button
                key="submit"
                type="primary"
                :loading="caseLoading"
                @click="handleAddIndustry"
                >Add Industry</a-button
              >
            </template>
            <a-form
              ref="modalFormRef"
              :model="formState"
              name="basic"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
            >
              <a-form-item
                label="Industry"
                name="industry"
                :rules="[{ required: true, message: 'Please input Industry!' }]"
              >
                <a-input v-model:value="formState.industry" />
              </a-form-item>
              <a-form-item
                label="Industry Code"
                name="bpmlTemplateCode"
                :rules="[
                  { required: true, message: 'Please input Industry Code!' },
                ]"
              >
                <a-input v-model:value="formState.bpmlTemplateCode" />
              </a-form-item>
            </a-form>
          </a-modal>
          <a-modal v-model:visible="editModalVisible" title="Edit Industry">
            <template #footer>
              <a-button
                key="back"
                @click="
                  () => {
                    editModalVisible = false;
                  }
                "
                >Return</a-button
              >
              <a-button
                key="submit"
                type="primary"
                :loading="caseLoading"
                @click="handleEditIndustry"
                >Update Industry</a-button
              >
            </template>
            <a-form
              ref="modalFormRef"
              :model="formState"
              name="basic"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
            >
              <a-form-item
                label="Industry"
                name="industry"
                :rules="[{ required: true, message: 'Please input Industry!' }]"
              >
                <a-input v-model:value="formState.industry" />
              </a-form-item>
              <a-form-item
                label="Industry Code"
                name="bpmlTemplateCode"
                :rules="[
                  { required: true, message: 'Please input Industry Code!' },
                ]"
              >
                <a-input v-model:value="formState.bpmlTemplateCode" />
              </a-form-item>
            </a-form>
          </a-modal>
          <!-- <ProjectLists /> -->
        </div>
      </a-col>
    </a-row>
    <!-- <project-create :onCancel="onCancel" :visible="visible" /> -->
  </Main>
</template>
<script>
import { ProjectHeader, ProjectSorting } from "./style";
import { Main } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, ref, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";

const MasterData = {
  name: "Master Data",
  components: { ProjectHeader, Main, ProjectSorting },
  setup() {
    const { state, dispatch } = useStore();
    const searchData = computed(() => state.headerSearchData);
    const projectData = computed(() => state.project.projectData);
    const { path } = useRoute().matched[1];
    const projectType = computed(() => state.project.projectType);
    const industries = computed(() => state.project.filteredIndustries);
    const visible = ref(false);
    const addModalVisible = ref(false);
    const editModalVisible = ref(false);
    const categoryActive = ref("all");
    const updateTableKey = ref(0);
    const fileList = ref([]);
    const fileListBpml = ref([]);
    const fileListQuestions = ref([]);
    const selectedIndustryId = ref();

    const beforeUploadBpml = (file) => {
      // Check if the file is an Excel file (has .xlsx extension)
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        // If the file is not an Excel file, display an error message
        message.error("You can only upload Excel files!");
      }

      fileListBpml.value = [...fileListBpml.value, file];
      //LOCAL
      // const fileDir = `./build/uploads/templates/`
      // PRODUCTION WARNING
      const fileDir = `./uploads/templates/`;
      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("dir", `.${fileDir}`);
      formData.append("file", file);
      dispatch("uploadExcelBpmls", formData);
    };

    const beforeUploadQuestions = (file) => {
      // Check if the file is an Excel file (has .xlsx extension)
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        // If the file is not an Excel file, display an error message
        message.error("You can only upload Excel files!");
      }

      fileListQuestions.value = [...fileListQuestions.value, file];
      //LOCAL
      // const fileDir = `./build/uploads/templates/`
      // PRODUCTION WARNING
      const fileDir = `./uploads/templates/`;
      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("dir", `.${fileDir}`);
      formData.append("file", file);
      // formData.append("assesment_id", params.id)
      dispatch("uploadExcelQuestions", formData);

      // Return true to allow the file to be uploaded
      // Return false to prevent the file from being uploaded
      // return isExcel;
    };

    const beforeFileUpload = (file) => {
      fileList.value = [...fileList.value, file];
      console.log(file.name, "file");
      const formData = new FormData();
      // PRODUCTION WARNING
      const fileDir = `/uploads/templates/used`;
      // LOCAL
      // const fileDir = `/build/uploads/templates/${selectedBpmlId.value}`
      formData.append("dir", `.${fileDir}`);
      formData.append("filesize", (file.size / 1024).toFixed());
      //
      formData.append("filename", file.name);
      formData.append("file", file);
      const payload = {
        formData,
      };
      dispatch("uploadCaseTemplate", payload);

      return true;
    };

    onMounted(() => {
      dispatch("fetchIndustries");
    });

    const onIndustrySearch = (searchValue) => {
      console.log(searchValue, "searchvalue");
      dispatch("industrySearchAction", searchValue);
    };

    watch(
      () => state.auth.users,
      function () {
        console.log("users updated");
        updateTableKey.value += 1;
      }
    );

    const onClickAddUser = () => {
      addModalVisible.value = true;
    };

    const handleAddIndustry = () => {
      console.log(formState, "formstate");
      dispatch("postIndustry", formState);
      addModalVisible.value = false;
      formState.bpmlTemplateCode = null;
      formState.industry = null;
    };

    const onClickEditIndustry = (payload) => {
      console.log(payload, "payload");
      formState.industry = payload.industry;
      formState.bpmlTemplateCode = payload.bpmlTemplateCode;
      selectedIndustryId.value = payload._id;
      editModalVisible.value = true;
    };

    const handleDeleteIndustry = (id) => {
      dispatch("removeIndustry", id);
    };

    const handleEditIndustry = () => {
      const payload = {
        id: selectedIndustryId.value,
        data: {
          industry: formState.industry,
          bpmlTemplateCode: formState.bpmlTemplateCode,
        },
      };
      console.log(payload.data, "formstateedit");
      dispatch("editIndustry", payload);
      editModalVisible.value = false;
      formState.industry = null;
      formState.bpmlTemplateCode = null;
    };

    const formState = reactive({
      industry: null,
      bpmlTemplateCode: null,
    });

    const columns = [
      {
        title: "No",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Industry",
        dataIndex: "industry",
        key: "industry",
      },
      {
        title: "Industry Code",
        dataIndex: "bpmlTemplateCode",
        key: "usebpmlTemplateCodername",
      },
      {
        title: "Operation",
        dataIndex: "operation",
        key: "operation",
      },
    ];

    const onSorting = (selectedItems) => {
      dispatch("sortingProjectByCategory", selectedItems);
    };

    const onSwitchChange = (val) => {
      console.log(val, "val");
      dispatch("fetchProjectsQuery", !val);
      state.project.projectType = val;
    };

    const onChangeCategory = (value) => {
      categoryActive.value = value;
      dispatch("filterProjectByStatus", value);
    };

    const onProjectSearch = (searchValue) => {
      console.log(searchValue, "searchvalue");
      dispatch("projectSearchAction", searchValue);
    };

    const showModal = () => {
      visible.value = true;
    };

    const onCancel = () => {
      visible.value = false;
    };
    return {
      onCancel,
      showModal,
      onChangeCategory,
      editModalVisible,
      fileListQuestions,
      beforeUploadQuestions,
      onProjectSearch,
      projectType,
      onSwitchChange,
      onSorting,
      beforeFileUpload,
      fileListBpml,
      beforeUploadBpml,
      addModalVisible,
      selectedIndustryId,
      onClickAddUser,
      onClickEditIndustry,
      searchData,
      categoryActive,
      projectData,
      formState,
      path,
      handleDeleteIndustry,
      onIndustrySearch,
      handleEditIndustry,
      updateTableKey,
      handleAddIndustry,
      columns,
      visible,
      industries,
    };
  },
};

export default MasterData;
</script>
