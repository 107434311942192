<template>
  <sdCards>
    <template v-slot:title>
      <div style="display: flex; justify-content: space-between" class="">
        <p>Files </p>
        <a-upload
            v-model:file-list="fileList"
            name="file"
            :beforeUpload="beforeFileUpload"
            :headers="headers"
            :show-upload-list="false"
            @change="handleUploadChange"
          >
            <sdButton v-if="user.role === 'Admin'" @click="showAddModal" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px;" outlined type="primary">
              <sdFeatherIcons type="upload" style=" margin-right: 15px" /> Upload File
            </sdButton>
          </a-upload>
        <!-- <sdButton @click="showAddModal" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px;" outlined type="primary">
            <sdFeatherIcons type="upload" style="margin-right: 10px" size="14" />
          Upload File
        </sdButton> -->
      </div>
    </template>
    <div class="file-list">
      <div v-for="projFile in projectFiles" :key="projFile._id" class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/other-format.png`)" alt="File Logo" />
            <!-- <img :src="require(`@/static/img/files/zip.png`)" alt="File Logo" />
            <img :src="require(`@/static/img/files/zip.png`)" alt="File Logo" />
            <img :src="require(`@/static/img/files/zip.png`)" alt="File Logo" />
            <img :src="require(`@/static/img/files/zip.png`)" alt="File Logo" /> -->
          </div>
          <div class="file-single__content">
            <span class="file-name">{{projFile.filename}}</span>
            <span class="file-size">{{projFile.filesize}} MB</span>
            <span class="file-content-action">
              <a @click="() => downloadFile(projFile.filepath)" to="#">Download</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <a to="#">
                <sdFeatherIcons type="trash-2" size="14" />
                Delete
              </a>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>

      <!-- <div class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/files/pdf.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">Product-guidelines.pdf</span>
            <span class="file-size">522 KB</span>
            <span class="file-content-action">
              <a to="#">View</a>
              <a to="#">Download</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <a to="#">
                <sdFeatherIcons type="eye" size="14" />
                View
              </a>
              <a to="#">
                <sdFeatherIcons type="edit" size="14" />
                Edit
              </a>
              <a to="#">
                <sdFeatherIcons type="trash-2" size="14" />
                Delete
              </a>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>

      <div class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/files/psd.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">admin-wireframe.psd</span>
            <span class="file-size">2.05 MB</span>
            <span class="file-content-action">
              <a to="#">Download</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>

      <div class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/files/jpg.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">Wirefram-escreenshots.jpg</span>
            <span class="file-size">522 KB</span>
            <span class="file-content-action">
              <a to="#">View</a>
              <a to="#">Download</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <a to="#">
                <sdFeatherIcons type="eye" size="14" />
                View
              </a>
              <a to="#">
                <sdFeatherIcons type="edit" size="14" />
                Edit
              </a>
              <a to="#">
                <sdFeatherIcons type="trash-2" size="14" />
                Delete
              </a>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div>

      <div class="file-list__single d-flex">
        <div class="file-single-info d-flex">
          <div class="file-single-logo">
            <img :src="require(`@/static/img/files/png.png`)" alt="File Logo" />
          </div>
          <div class="file-single__content">
            <span class="file-name">Logo.png</span>
            <span class="file-size">522 KB</span>
            <span class="file-content-action">
              <a to="#">View</a>
              <a to="#">Download</a>
            </span>
          </div>
        </div>
        <div class="file-single-action">
          <sdDropdown class="wide-dropdwon">
            <template #overlay>
              <div class="dropdown-more">
                <a to="#">
                  <sdFeatherIcons type="eye" size="14" />
                  View
                </a>
                <a to="#">
                  <sdFeatherIcons type="edit" size="14" />
                  Edit
                </a>
                <a to="#">
                  <sdFeatherIcons type="trash-2" size="14" />
                  Delete
                </a>
              </div>
            </template>
            <a to="#">
              <sdFeatherIcons type="more-horizontal" size="16" />
            </a>
          </sdDropdown>
        </div>
      </div> -->
    </div>
  </sdCards>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { API_ENDPOINT } from '@/services/api/axiosConfig.js';
const FileListCard = defineComponent({
  name: 'FileListCard',
  setup() {
    const { state, dispatch } = useStore();
    const projectFiles = computed(() => state.project.projectFiles);
    const project = computed(() => state.project.singleData);
    const { params } = useRoute();
    const user = computed(() => state.auth.currentAccount);
    const fileList = ref([]);
    onMounted(() => dispatch('fetchProjectFiles', params.id))
    const downloadFile = (url) => {
      window.open(`${API_ENDPOINT}/public/${url}`)
    }

    const beforeFileUpload = file => {
      fileList.value = [...fileList.value, file];
      console.log(file.name, 'file')
      const formData = new FormData();
      // PRODUCTION WARNING
      const fileDir = `/uploads/PROJECT ID ${project.value._id}`
      // const fileDir = `/uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`
      // formData.append("dir",`./uploads/PROJECT ID ${project.value._id}/${selectedBpml.value}`)
      // LOCAL
      // const fileDir = `/build/uploads/PROJECT ID ${project.value._id}`
      // console.log((file.size / 1024).toFixed(), 'fizesize')
      formData.append("dir", `.${fileDir}`)
      formData.append("filesize", (file.size / 1024).toFixed())
      //
      formData.append("filename", file.name)
      formData.append("project_id", project.value._id)
      formData.append("file", file)
      console.log(project.value._id, 'id')

      console.log(formData, 'formdatavaleus')

      dispatch('uploadProjectFile', formData)

      return true
    }

    const handleUploadChange = info => {
      console.log(info)
          // if (info.file.status !== 'uploading') {
          //   console.log(info.file, info.fileList);
          // }
          // if (info.file.status === 'done') {
          //   message.success(`${info.file.name} file uploaded successfully`);
          // } else if (info.file.status === 'error') {
          //   message.error(`${info.file.name} file upload failed.`);
          // }
        };

    return {
      projectFiles,
      downloadFile,
      user,
      handleUploadChange,
      beforeFileUpload,
    }
  }
});

export default FileListCard;
</script>
