<template>
  <div class="">
    <div style="height=512px" class="spin" v-if="!selectedBpmlId">
        <a-empty description="Please select a BPML" />
    </div>
    <div class="" style="display: block;" v-else>
      <a-row :gutter="25">
        <a-col :xl="24">
        <div class="" style="display: flex; justify-content: space-between">
          <div style="width: 100%" class="">
            <nav style="margin-bottom: 20px">
              <a-menu @change="getAssesmentQuestions" v-model:selectedKeys="levelMenu" mode="horizontal">
                <a-menu-item :key="1" value="doc">
                  <span style="font-size: 16px;">{{selectedBpml}}</span>
                </a-menu-item>
              </a-menu>
            </nav>
          </div>
        <sdButton v-if="user.role === 'Admin'" @click="showAddModal" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px;" outlined type="primary">
            <sdFeatherIcons type="plus" size="14" />
          Add Question
        </sdButton>
        </div>
        </a-col>
        <a-col :xl="24">
        <div style="height: 512px; overflow: scroll" class="">
          <a-collapse style="height: inherit; margin-top: 5px;" v-model:activeKey="activeKey" accordion :bordered="false">
              <template #expandIcon="props">
                <sdFeatherIcons type="plus" v-if="!props.isActive" size="14" />
                <sdFeatherIcons type="minus" v-else size="14" />
              </template>
              <a-collapse-panel v-for="question in assesmentQuestions" :key="question._id" :header="question.question">
                <sdHeading as="h4" v-if="question.assesmentAnswer">Assesment Answer</sdHeading>
                <p>
                  {{question.assesmentAnswer}}
                </p>
                <sdHeading as="h4">Project Answer</sdHeading>
                <p>
                  {{question.answer}}
                </p>
                <sdHeading  v-if="user.role === 'Admin'" as="h4">Actions</sdHeading>
                <div class="panel-actions">
                <sdButton v-if="user.role === 'Admin'" @click="() => onClickEditQuestion(question)" :outlined="true" type="warning">
                  <sdFeatherIcons type="edit" size="14" />
                  <span>Edit</span>
                </sdButton>
                <a-popconfirm
                  v-if="user.role === 'Admin'"
                  title="Are you sure delete this question?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => onClickDeleteQuestion(question)"
                  @cancel="cancel"
                >
                  <sdButton :outlined="true" type="danger">
                    <sdFeatherIcons type="trash-2" size="14" />
                    <span>Delete</span>
                  </sdButton>
                </a-popconfirm>
              </div>
              </a-collapse-panel>
            </a-collapse>
        </div>
        </a-col>
      </a-row>
    </div>
    <a-modal style="width: 75em" v-model:visible="addVisible" title="Add Question">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleAddQuestion">Add Question</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Question"
              name="question"
              :rules="[{ required: true, message: 'Please input question header!' }]"
            >
              <a-input v-model:value="formState.question" />
            </a-form-item>
            <!-- <a-form-item
              label="Level"
              name="level"
              :rules="[{ required: true, message: 'Please input assessment level' }]"
            >
              <a-input-number style="width: 100%" v-model:value="formState.level" />
            </a-form-item> -->
            <a-form-item
              label="Project Answer"
              name="answer"
            >
              <a-textarea :row="15" v-model:value="formState.answer" />
            </a-form-item>
          </a-form>
    </a-modal>
    <a-modal style="width: 75em" v-model:visible="updateVisible" title="Edit Question">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleEditQuestion">Update Question</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Question"
              name="question"
              :rules="[{ required: true, message: 'Please input question header!' }]"
            >
              <a-input v-model:value="formState.question" />
            </a-form-item>
            <!-- <a-form-item
              label="Level"
              name="level"
              :rules="[{ required: true, message: 'Please input assessment level' }]"
            >
              <a-input-number v-model:value="formState.level" />
            </a-form-item> -->
            <a-form-item
              label="Project Answer"
              name="answer"
            >
              <a-input v-model:value="formState.answer" />
            </a-form-item>
          </a-form>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch, computed, reactive } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const { state, dispatch } = useStore();
    const bpmlQuestions = computed(() => state.project.bpmlQuestions);
    const selectedBpmlId = computed(() => state.project.selectedBpmlId);
    const selectedBpml = computed(() => state.project.selectedBpml);
    const assesmentQuestions = computed(() => state.project.assesmentQuestions);
    const user = computed(() => state.auth.currentAccount);
    const projectType = computed(() => state.project.projectType);
    const project = computed(() => state.project.singleData);
    const activeKey = ref(['1']);
    const updateVisible = ref(false);
    const addVisible = ref(false);
    const levelMenu = ref([1]);

    console.log(project.value, 'project in question')

    const getAssesmentQuestions = () => {
      if (state.project.singleData && selectedBpml.value) {
        console.log(selectedBpml.value, 'code')
        const payload = {
          assesment_id: project.value.assesment._id,
          code: selectedBpml.value,
          level: 3,
          project_id: project.value._id,
          type: 'all',
        }
        console.log(payload, 'payload questions')
        dispatch('fetchAssesmentQuestions', payload)
        console.log(bpmlQuestions.value, 'bpmlQuestions questions')
        // dispatch('fetchQuestionsByBpml', state.project.selectedBpmlId)
      }
    }
    
    watch (() => levelMenu.value, function () {
      getAssesmentQuestions();
    });

    const showAddModal = () => {
      addVisible.value = true
    }
    
    const onClickDeleteQuestion = (question) => {
      formState.id = question._id
      formState.question = question.question
      formState.level = question.level
      formState.answer = question.answer
      formState.assesmentAnswer = question.assesmentAnswer
      formState.assesment_id = question.assesment_id
      formState.bpmlCode = question.bpmlCode
      const payload = {
        id: formState.id,
        data: formState
      }
      dispatch('removeQuestion', payload);
    }
    
    const onClickEditQuestion = (question) => {
      console.log(question, 'question')
      formState.id = question._id
      formState.question = question.question
      formState.level = question.level
      formState.answer = question.answer
      formState.assesmentAnswer = question.assesmentAnswer
      formState.assesment_id = question.assesment_id
      formState.bpmlCode = question.bpmlCode
      updateVisible.value = true
    }

    const handleCancel = () => {
      updateVisible.value = false;
      addVisible.value = false;
    }

    const handleEditQuestion = () => {
      const payload = {
        id: formState.id,
        data: formState
      }
      dispatch('editQuestion', payload);
      updateVisible.value = false;
    }

    const handleAddQuestion = () => {
      formState.assesment_id = project.value.assesment._id
      formState.bpmlCode = selectedBpml.value
      formState.project_id = project.value._id
      formState.level = 2
      console.log(formState, 'formStaat')
      if (projectType.value === false) {
        formState.project_id = null
      }
      dispatch('createQuestion', formState);
      addVisible.value = false;
    }

    watch (() => state.project.selectedBpmlId, function () {
      getAssesmentQuestions();
    });

    const formState = reactive({
      id: null,
      question: null,
      level: 3,
      answer: null,
      assesmentAnswer: null,
      bpmlCode: null,
      project_id: null,
      assesment_id: null,
      type: 'non-assesment',
    });

    return {
      activeKey,
      updateVisible,
      addVisible,
      formState,
      assesmentQuestions,
      bpmlQuestions,
      onClickDeleteQuestion,
      showAddModal,
      selectedBpmlId,
      selectedBpml,
      getAssesmentQuestions,
      handleCancel,
      handleAddQuestion,
      levelMenu,
      onClickEditQuestion,
      handleEditQuestion,
      user,
    }
  }
}
</script>

<style>

</style>