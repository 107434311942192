<template>
  <a-row :gutter="25">
    <a-col :xs="24">
      <ProjectList>
        <div class="table-responsive">
          <a-table :loading="projectListLoading" v-if="projectType" :pagination="pagination" :dataSource="dataSource" :columns="columns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <div style="margin-left: 55px;">
                <sdDropdown
                  class="wide-dropdwon"
                >
                  <template #overlay>
                    <div>
                      <router-link :to="`/project/detail/${record.key}`">View</router-link>
                      <a v-on:click="() => handleSelectProject(record.key)" v-if="user.role === 'Admin'">
                        <span>Edit</span>
                      </a>
                      <a @click="() => deleteProject(record.key)" v-if="user.role === 'Admin'">
                        <span>Delete</span>
                      </a>
                    </div>
                  </template>
                  <a to="#">
                    <sdFeatherIcons type="more-horizontal" size="18" />
                  </a>
                </sdDropdown>
              </div>
            </template>
            <template v-if="column.key === 'description'">
              <ProjectListTitle>
                <sdHeading as="h4">
                  <router-link :to="`/project/detail/${record.key}`">{{record.company}}</router-link>
                </sdHeading>
                <p>{{record.productRaw}} - {{record.industry}} - {{record.projectCode}}</p>
              </ProjectListTitle>
            </template>
          </template>
          </a-table>
          <a-table :loading="projectListLoading" v-else :pagination="pagination" :dataSource="dataSource" :columns="templateColumns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <div style="margin-left: 55px;">
                <sdDropdown
                  class="wide-dropdwon"
                >
                  <template #overlay>
                    <div>
                      <router-link :to="`/project/detail/${record.key}`">View</router-link>
                      <a v-on:click="() => handleSelectProject(record.key)" v-if="user.role === 'Admin'">
                        <span>Edit</span>
                      </a>
                      <a @click="() => deleteProject(record.key)" v-if="user.role === 'Admin'">
                        <span>Delete</span>
                      </a>
                    </div>
                  </template>
                  <a to="#">
                    <sdFeatherIcons type="more-horizontal" size="18" />
                  </a>
                </sdDropdown>
              </div>
            </template>
            <template v-if="column.key === 'description'">
              <ProjectListTitle>
                <sdHeading as="h4">
                  <router-link :to="`/project/detail/${record.key}`">{{record.company}}</router-link>
                </sdHeading>
                <p>{{record.productRaw}} - {{record.industry}} - {{record.projectCode}}</p>
              </ProjectListTitle>
            </template>
          </template>
          </a-table>
        </div>
      </ProjectList>
    <project-edit :onCancel="onCancel" :visible="visible" />
    </a-col>
    <a-col :xs="24" class="pb-30">
    </a-col>
  </a-row>
</template>
<script>
import { computed, ref, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import ProjectEdit from './ProjectEdit.vue';
import { ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
const columns = [
  {
    title: 'Project ID',
    dataIndex: 'projectId',
    key: 'id',
  },
  {
    title: 'Project Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'End Date',
    dataIndex: 'deadline',
    key: 'deadline',
  },
  // {
  //   title: 'Assigned To',
  //   dataIndex: 'assigned',
  //   key: 'assigned',
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Progress',
    dataIndex: 'completion',
    key: 'completion',
  },

  {
    title: '',
    dataIndex: 'action',
    key: 'action',
  },
];
const templateColumns = [
  {
    title: 'Project ID',
    dataIndex: 'projectId',
    key: 'id',
  },
  {
    title: 'Project Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
];

const ProjectLists = {
  name: 'ProjectLists',
  components: { ProjectListTitle, ProjectListAssignees, ProjectList, ProjectEdit },
  setup() {
    const { state, dispatch } = useStore();
    const projects = computed(() => state.project.filteredProjectData);
    const projectType = computed(() => state.project.projectType);
    const projectListLoading = computed(() => state.project.projectListLoading);
    const user = computed(() => state.auth.currentAccount);
    const current = ref(0);
    const visible = ref(false);
    const pageSize = ref(0);

    const onShowSizeChange = (c, p) => {
      current.value = c;
      pageSize.value = p;
    };

    // watch (() => state.project.projectType, function () {
    //   state.project.projectListLoading = true;
    // });

    onUnmounted(() => { state.project.filteredProjectData = [] })

    const deleteProject = id => {
      const targetData = projects.value.filter(item => item._id === id)[0];
      const payload = { id: targetData._id, data: targetData }
      dispatch('removeProject', payload)
    }

    const onCancel = () => {
      visible.value = false;
    };

    const showModal = () => {
      visible.value = true;
    };

    const onHandleChange = (c, p) => {
      current.value = c;
      pageSize.value = p;
    };
    const payload = {
        isTemplate: !projectType.value,
        userId: user.value._id
      }
      if (user.value.role === 'Admin') {
          dispatch('fetchProjectsQueryAdmin', payload)
        } else {          
          dispatch('fetchProjectsQuery', payload)
      }
    console.log(state.project.projectData, 'prjdata')

    const handleSelectProject = (val) => {
      const targetProject = projects.value.filter(x => x._id === val)[0]
      const selectedProject = {...targetProject}
      state.project.selectedProject = selectedProject
      showModal();
      console.log(state.project.selectedProject, 'sl')
    }

    const dataSource = computed(
      () =>
        projects.value.length &&
        projects.value.map((value) => {
          const { _id, status, startDate, endDate, year, product, company, assesment, projectId, industry, projectCode, progress } = value;
          return {
            key: _id,
            id: <span class="year">{_id}</span>,
            productRaw: product,
            industry,
            company,
            projectId,
            assesment,
            projectCode,
            description: (
              <ProjectListTitle>
                <sdHeading as="h4">
                  <router-link to={`/project/detail/${_id}`}><span style={'color: #5F63F2 !important;'}>{assesment}</span></router-link>
                </sdHeading>
                <p>{product} - {industry} - {projectCode}</p>
              </ProjectListTitle>
            ),
            year: <span class="year">{year}</span>,
            product: <span class="year">{product}</span>,
            startDate: <span class="date-started">{startDate}</span>,
            deadline: <span class="date-finished">{endDate}</span>,
            assigned: (
              <ProjectListAssignees>
                <ul>
                  <li>
                    <img src={require(`@/static/img/users/1.png`)} alt="" />
                  </li>
                  <li>
                    <img src={require(`@/static/img/users/2.png`)} alt="" />
                  </li>
                  <li>
                    <img src={require(`@/static/img/users/3.png`)} alt="" />
                  </li>
                </ul>
              </ProjectListAssignees>
            ),
            status: <a-tag class={status}>{status}</a-tag>,
            completion: (
              <div class="project-list-progress" style="margin-right: 5px">
                <a-progress
                  percent={progress}
                  strokeWidth={5}
                  class="progress-primary"
                />
              </div>
            ),
          };
        }),
    );
    return {
      dataSource,
      onHandleChange,
      visible,
      showModal,
      onCancel,
      onShowSizeChange,
      projectListLoading,
      columns,
      handleSelectProject,
      deleteProject,
      projectType,
      templateColumns,
      user,
      pagination: {
        pageSize: 9,
      },
      projects,
    };
  },
};

export default ProjectLists;
</script>
