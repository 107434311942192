<template>
<!-- <div class="">
    <button v-on:click='onExport' >Save</button>
  <ejs-documenteditorcontainer ref="doceditcontainer" :enableWordExport='true' height="1150px" :serviceUrl='serviceUrl' :enableToolbar='true'> </ejs-documenteditorcontainer>
</div> -->
<div class="control-section">
    <div class="sample-container">
        <div class="default-section">
          <div ref="de_titlebar" id="documenteditor_titlebar" class="e-de-ctn-title">
            <div v-if="bpmlCode" v-on:keydown="titleBarKeydownEvent" class="single-line" id="documenteditor_title_contentEditor" contenteditable="false">
                <label style="font-size: 16px;" v-on:blur="titleBarBlurEvent" id="documenteditor_title_name" :style="titileStyle" >{{docName}}</label>
                <ejs-button id="de-print" :style="buttonStyle" v-on:click="onSave" title="Save this document.">
                <sdFeatherIcons type="save" size="13" />
                  Save</ejs-button>	
            </div>    
          </div>
          <ejs-documenteditorcontainer :enableWordImport="true"  :data="docData"  ref="doceditcontainer" :enableWordExport='true' height="1150px" :serviceUrl='serviceUrl' :enableToolbar='true'> </ejs-documenteditorcontainer>         
        </div>
    </div>
</div>
</template>

<script>
  import { DocumentEditorContainerComponent, Toolbar, WordExport } from '@syncfusion/ej2-vue-documenteditor';
  // import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons"; //Component registeration
  import { message } from 'ant-design-vue';
  import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
  import { API_ENDPOINT } from '@/services/api/axiosConfig.js';
  import { computed, toRefs, watch, reactive } from 'vue';
  import PropTypes from 'vue-types';
  import { useStore } from 'vuex';
  import axios from 'axios'; // HTTP client library
  export default {
    name: 'App',
    components: {
      // Declaring component
      // 'ejs-dropdownbutton' : DropDownButtonComponent,
      "ejs-button": ButtonComponent,
      'ejs-documenteditorcontainer' : DocumentEditorContainerComponent
    },
    data () {
      return {
        docContent: null,
        documentName : 'PROJECT ID 10001 - MNG-OS-0020',
          documentTitle: 'Untitled Document',
          buttonStyle: `
          float: right;
          background: transparent;
          border-color: transparent;
          box-shadow: none;
          border-radius: 0;
          margin-bottom: 10px;
          color: inherit;
          `,
          iconStyle: 'float:right;background: transparent;box-shadow:none;border-color: transparent;border-radius: 2px;color:inherit;font-size:12px;text-transform:capitalize;margin-top:4px;height:28px;font-weight:400;font-family:inherit;',
          titileStyle: 'text-transform:capitalize;font-weight:400;font-family:inherit;text-overflow:ellipsis;white-space:pre;overflow:hidden;user-select:none;cursor:text',
          openIconCss: 'e-de-icon-Open e-de-padding-right',
          printIconCss: 'e-de-icon-Print e-de-padding-right',
          exportIconCss: 'e-de-icon-Download e-de-padding-right',
          exportItems: [
            { text: 'Microsoft Word (.docx)', id: 'word' },
            { text: 'Syncfusion Document Text (.sfdt)', id: 'sfdt' }
        ],
        serviceUrl:'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/'
      };
    },
    props: {
      docName: PropTypes.string,
      projectId: PropTypes.string,
      bpmlCode: PropTypes.string,
    },
    setup (props) {
      const { state, dispatch } = useStore();
      const selectedBpml = computed(() => state.project.selectedBpml);
      const selectedBpmlBodyPath = computed(() => state.project.selectedBpmlBodyPath);
      const { docName, projectId, bpmlCode } = toRefs(props);
      console.log(docName.value, 'docname')
      const docData = reactive({ data: null });
      watch(() => state.project.selectedBpml, function() {
        loadDocument()
      });

      const loadDocument = async () => {
        console.log( `/PROJECT ID ${projectId.value}/${bpmlCode.value}/${docName.value}.sfdt`, "ANSN;")
        console.log(selectedBpmlBodyPath.value, "ABSN;")
        dispatch('loadSfdtDocument', selectedBpmlBodyPath.value);

        // use a reactive property to store the docx file data

        // make an HTTP request to the API to retrieve the docx file
        // fetch(`${API_ENDPOINT}/public${selectedBpmlBodyPath.value}`)
        //   .then(response => response.arrayBuffer())
        //   .then(buffer => {
        //     // convert the file contents to a base64-encoded string
        //     const base64 = Buffer.from(buffer).toString('base64');
        //     // set the docData.data property to the base64-encoded string
        //     docData.data = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64;
        //     console.log(docData.data)
        //   });
          // const response = await axios.get(`${API_ENDPOINT}/public${selectedBpmlBodyPath.value}`);
          // const docxContent = response.data;
          // // const toolbar = new Toolbar();
          // // const sfdt = toolbar.convertToSfdt(docxContent);
          // const sfdt = this.$refs.doceditcontainer.ej2Instances.convertToSfdt(docxContent, 'docx');
      }

      loadDocument();

      return {
        selectedBpml,
        docData,
      }
    },
    watch: {
      // '$store.state.project.selectedBpml': function () {
      //   this.loadSfdt();
      // },
      '$store.state.project.sfdt': function() {
        var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor;
        obj.open(JSON.stringify(this.$store.state.project.sfdt));
        // obj.documentName='Getting Started';
        // this.$refs.doceditcontainer.ej2Instances.serviceUrl = this.serviceUrl;
        // this.$refs.doceditcontainer.ej2Instances.documentChange = () => {
        //       // this.documentChangedEvent();
        //   };
      }
    },
    mounted: function() {
        //Set zoom factor.
        this.$refs.doceditcontainer.ej2Instances.documentEditor.zoomFactor = 1.5;
    },
    methods: {
        loadSfdt: async function () {
            const response = await axios.get(`${API_ENDPOINT}/public${this.$store.state.project.selectedBpmlBodyPath}`, {
              responseType: 'blob'
            });


            // Convert the binary stream to a base64-encoded string
            // const docxFile = Buffer.from(response.data, 'binary').toString('base64');
            const docxContent = new File([response.data], 'mydoc.docx')
            // console.log(docxContent, 'content')
            // const toolbar = new Toolbar();
            // const sfdt = toolbar.convertToSfdt(docxContent);
            // const formData = new FormData();
            // formData.append('files', docxContent)
            // const syncfusionApi = await axios.post('https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import', formData, {
            //   headers: {
            //     'Content-Type': 'multipart/form-data'
            //   }
            // })
            // console.log(syncfusionApi.data, 'syncfusiondata')
            // this.$store.state.project.sfdt = syncfusionApi.data,
            // this.$refs.doceditcontainer.ej2Instances.documentEditor.open(syncfusionApi.data);
            console.log(docxContent, 'sfdt')
        },
        onSave: function () {
          this.$refs.doceditcontainer.ej2Instances.documentEditor.saveAsBlob('Docx').then((exportedDocument) => {
              // The blob can be processed further
              const blobFile = new File([exportedDocument], "sample.docx")
              const formData = new FormData();
              // formData.append("dir", `./build/uploads/PROJECT ID ${this.projectId}/${this.bpmlCode}`)
              // PRODUCTION WARNING
              formData.append("dir", `./uploads/PROJECT ID ${this.projectId}/${this.bpmlCode}`)
              formData.append("filename", `${this.docName}.docx`)
              formData.append("file", blobFile)

              this.$store.dispatch('uploadBpmlDocx', formData)
              console.log(this.$store.state.project.filteredBpmls, 'bpmlObjs')

              // fetch(`http://localhost:7000/api/upload/bpmlBody`, {method:"POST", body:formData, headers: {
              // }})
              //   .then(response => console.log(response.text(), 'respons text'))
              // let blobEl = document.createElement("a");
              // const url = URL.createObjectURL(exportedDocument)
              // blobEl.download = `${this.docName}.docx`
              // blobEl.href = url
              // document.body.appendChild(blobEl)
              // blobEl.click()
              // document.body.removeChild(blobEl)
          });
          this.$refs.doceditcontainer.ej2Instances.documentEditor.saveAsBlob('Sfdt').then((exportedDocument) => {
              console.log(exportedDocument, 'blob2')
              // The blob can be processed further
              const blobFile = new File([exportedDocument], "sample.sfdt")
              const formData = new FormData();
              // LOCAL
              // const fileDir = `./build/uploads/PROJECT ID ${this.projectId}/${this.bpmlCode}`
              // formData.append("dir", fileDir)
              // PRODUCTION WARNING
              const fileDir = `./uploads/PROJECT ID ${this.projectId}/${this.bpmlCode}`
              formData.append("dir", fileDir)
              formData.append("filename", `${this.docName}.sfdt`)
              formData.append("file", blobFile)

              this.$store.dispatch('uploadBpmlDocx', formData)
              const selectedBpmlBodyPath = this.$store.state.project.selectedBpmlBodyPath
              console.log(selectedBpmlBodyPath.substring(0, selectedBpmlBodyPath.length - 5))
              const selectedBpml = this.$store.state.project.selectedBpmlId
              const payload = { code: selectedBpml, data: { bpmlBodyFile: selectedBpmlBodyPath.substring(0, selectedBpmlBodyPath.length - 5) + '.docx' } }
              this.$store.dispatch('updateBpmlBody', payload)
              console.log(payload, 'payload')
              // const filteredBpmls = this.$store.state.project.filteredBpmls
              // for (const bpml of filteredBpmls) {
              //   if (bpml.bpmlCode === selectedBpml) {
              //     const newBpml = {...bpml}
              //     newBpml.bpmlBodyFile = fileDir
              //     console.log(newBpml, 'selectedBpml')
              //   }
              // }
              message.success('Document Saved')
              // The blob can be processed further
              // let blobEl = document.createElement("a");
              // const url = URL.createObjectURL(exportedDocument)
              // blobEl.download = `${this.docName}.sfdt`
              // blobEl.href = url
              // document.body.appendChild(blobEl)
              // blobEl.click()
              // document.body.removeChild(blobEl)
          });
            //  this.$refs.doceditcontainer.ej2Instances.documentEditor.save( this.$refs.doceditcontainer.ej2Instances.documentEditor.documentName === '' ? 'sample' :  this.$refs.doceditcontainer.ej2Instances.documentEditor.documentName, 'Docx');
            // this.$refs.documenteditor.ej2Instances.print()

    },
    },
    provide: {
      DocumentEditorContainer: [Toolbar, WordExport]
    }
  }
</script>

<style>
  @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
  @import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
  @import "../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
  .e-de-ctn-title {
    height: 30px;
    padding-top: 2px;
    padding-left: 10px;
    background-color: #0D6EFD;
  }
</style>