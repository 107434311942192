<template>
  <sdPageHeader title="Assesments">
    <template v-slot:subTitle>
      <span class="" style="margin-left: 23px">{{assesmentList.length}} Active Assesments </span>
    </template>
    <template v-slot:buttons>
      <!-- <sdButton v-if="!assesmentType" @click="showModal" key="1" type="success">
        <sdFeatherIcons type="upload" style="margin-right: 5px" size="16" /> Bulk Upload Excel
      </sdButton> -->
      <sdButton @click="showModal" key="1" type="primary">
        <sdFeatherIcons type="plus" size="16" /> Create Assesment
      </sdButton>
    </template>
  </sdPageHeader>

  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <a-row :gutter="25">
          <a-col :xl="20" :lg="20" :md="20" :sm="24" :xs="24">
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-search">
                  <sdAutoComplete @change="onAssesmentSearch" :dataSource="searchData" placeholder="Search Assesment By Company" patterns />
                </div>
              </div>
            </ProjectSorting>
          </a-col>
          <a-col :xl="4" :lg="4" :md="4" :sm="24" :xs="24">
            <div class="" style="float: right">
              <span> Assesment Type : </span>
              <a-switch @change="onSwitchChange" style="margin-left: 15px" v-model:checked="assesmentType" checked-children="Project" un-checked-children="Template" />
            </div>
          </a-col>
        </a-row>
        <div>
          <AssesmentLists />
          <!-- <router-view name="child"></router-view> -->
        </div>
      </a-col>
    </a-row>
    <!-- <sdCreateProject :onCancel="onCancel" :visible="visible" /> -->
    <AssesmentCreate :onCancel="onCancel" :visible="visible" />
  </Main>
</template>
<script>
import { ProjectHeader, ProjectSorting } from './style';
import AssesmentLists from './overview/List.vue';
import AssesmentCreate from './overview/AssesmentCreate.vue';
import { Main } from '../styled';
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const Assesment = {
  name: 'Assesment',
  components: { ProjectHeader, ProjectSorting, Main, AssesmentLists, AssesmentCreate },
  setup() {
    const { state, dispatch } = useStore();
    const searchData = computed(() => state.headerSearchData);
    const assesmentList = computed(() => state.project.filteredAssesments);
    const assesmentType = computed(() => state.project.assesmentType);
    console.log(assesmentList.value, 'list')
    const { path } = useRoute().matched[1];
    const visible = ref(false);
    const user = computed(() => state.auth.currentAccount);
    const checked1 = ref(true)
    const categoryActive = ref('all');

    const onAssesmentSearch = (searchValue) => {
      console.log(searchValue, 'searchvalue')
      dispatch('assesmentSearchAction', searchValue)
    }

    onMounted(() => decideFetch(!state.project.assesmentType))

    const onSorting = (selectedItems) => {
      dispatch('sortingProjectByCategory', selectedItems);
    };

    const decideFetch = (val) => {
      const payload = {
        isTemplate: val,
        userId: user.value._id
      }
      if (user.value.role === 'Admin') {
          dispatch('fetchAssesmentsQueryAdmin', payload)
        } else {          
          console.log('role non admin')
          dispatch('fetchAssesmentsQueryUser', payload)
      }
    }

    const onSwitchChange = (val) => {
      console.log(val, 'val')
      // dispatch('fetchAssesmentsQuery', !val)
      decideFetch(!val)
      state.project.assesmentType = val
    }

    const onChangeCategory = (value) => {
      categoryActive.value = value;
      dispatch('filterProjectByStatus', value);
    };

    const showModal = () => {
      visible.value = true;
    };

    const onCancel = () => {
      visible.value = false;
    };
    return {
      onCancel,
      showModal,
      onChangeCategory,
      onAssesmentSearch,
      onSorting,
      searchData,
      categoryActive,
      checked1,
      assesmentList,
      path,
      assesmentType,
      onSwitchChange,
      visible,
    };
  },
};

export default Assesment;
</script>
