<template>
  <div class="">
    <div class="spin" v-if="!selectedBpmlId">
        <a-empty description="Please select a BPML" />
    </div>
    <div class="" style="display: block; height: 612px" v-else>
      <a-row :gutter="25">
        <a-col :xl="24">
        </a-col>
        <a-col :xl="24">
        <div style="height: 512px; overflow: scroll" class="">
          <a-collapse style="height: inherit; margin-top: 5px;" v-model:activeKey="activeKey" accordion :bordered="false">
            <template #expandIcon="props">
              <sdFeatherIcons type="plus" v-if="!props.isActive" size="14" />
              <sdFeatherIcons type="minus" v-else size="14" />
            </template>
            <a-collapse-panel v-for="question in assesmentQuestions" :key="question._id">
              <template v-slot:header>
                {{question.question}}
              </template>
              <sdHeading as="h4">Assesment Answer</sdHeading>
              <p>
                {{question.assesmentAnswer}}
              </p>
              <sdHeading as="h4">Project Answer</sdHeading>
              <p>
                {{question.answer}}
              </p>
              <sdHeading as="h4">Actions</sdHeading>
              <div class="panel-actions">
                <sdButton v-if="user.role === 'Admin'" @click="() => onClickEditQuestion(question)" :outlined="true" type="warning">
                  <sdFeatherIcons type="edit" size="14" />
                  <span>Edit</span>
                </sdButton>
                <a-popconfirm
                  v-if="user.role === 'Admin'"
                  title="Are you sure delete this question?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => onClickDeleteQuestion(question)"
                  @cancel="cancel"
                >
                  <sdButton :outlined="true" type="danger">
                    <sdFeatherIcons type="trash-2" size="14" />
                    <span>Delete</span>
                  </sdButton>
                </a-popconfirm>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        </a-col>
      </a-row>
    </div>
    <a-modal style="width: 75em" v-model:visible="addAssesmentModal" title="Add Question">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleAddQuestion">Add Question</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Question"
              name="question"
              :rules="[{ required: true, message: 'Please input question header!' }]"
            >
              <a-input v-model:value="formState.question" />
            </a-form-item>
            <a-form-item
              label="Assesment Answer"
              name="assesmentAnswer"
              :rules="[{ required: true, message: 'Please input Assesment Answer!' }]"
            >
              <a-textarea v-model:value="formState.assesmentAnswer" />
            </a-form-item>
            <a-form-item
              label="Level"
              name="level"
              :rules="[{ required: true, message: 'Please input assessment level' }]"
            >
              <a-input-number style="width: 100%" v-model:value="formState.level" />
            </a-form-item>
            <a-form-item
              label="Project Answer"
              name="answer"
            >
              <a-textarea :row="15" v-model:value="formState.answer" />
            </a-form-item>
            <a-form-item
              label="Type"
              name="type"
            >
              <div class="">
                <a-switch @change="onSwitchChange" style="margin-left: 15px" v-model:checked="visibilityType" checked-children="Project" un-checked-children="Assesment" />
              </div>
            </a-form-item>
          </a-form>
    </a-modal>
    <a-modal v-model:visible="updateAssesmentModal" title="Edit Question">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleEditQuestion">Update Question</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="Question"
              name="question"
              :rules="[{ required: true, message: 'Please input question header!' }]"
            >
              <a-input v-model:value="formState.question" />
            </a-form-item>
            <a-form-item
              label="Assesment Answer"
              name="assesmentAnswer"
              :rules="[{ required: true, message: 'Please input Assesment Answer!' }]"
            >
              <a-input v-model:value="formState.assesmentAnswer" />
            </a-form-item>
            <a-form-item
              label="Level"
              name="level"
              :rules="[{ required: true, message: 'Please input assessment level' }]"
            >
              <a-input-number v-model:value="formState.level" />
            </a-form-item>
            <a-form-item
              label="Project Answer"
              name="answer"
            >
              <a-input v-model:value="formState.answer" />
            </a-form-item>
          </a-form>
    </a-modal>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const { state, dispatch } = useStore();
    const bpmlQuestions = computed(() => state.project.bpmlQuestions);
    const user = computed(() => state.auth.currentAccount);
    const selectedBpmlId = computed(() => state.project.selectedBpmlId);
    const addAssesmentModal = computed(() => state.project.addAssesmentModal);
    const assesmentQuestions = computed(() => state.project.assesmentQuestions);
    const assesmentType = computed(() => state.project.assesmentType);
    const singleAssesment = computed(() => state.project.singleAssesment);
    const selectedLevel = computed(() => state.project.selectedLevel);
    const selectedBpmlObj = computed(() => state.project.selectedBpmlObj);
    const activeKey = ref(['2']);
    const updateAssesmentModal = ref(false);
    const addVisible = ref(false);
    const visibilityType = ref(false);
    

    const getQuestions = () => {
      if (singleAssesment.value != null && selectedBpmlObj.value != null) {
        let newBpml = selectedBpmlObj.value.bpmlCode
        let type = 'all'
        if (!singleAssesment.value.isTemplate) {
          type = 'assesment'
          newBpml = `${singleAssesment.value.project.projectCode}-${selectedBpmlObj.value.module}-${selectedBpmlObj.value.cat}-${selectedBpmlObj.value.codeId2}`
        }
        console.log(assesmentType.value, 'newBpml')
        console.log(newBpml, 'newBpml')
        // let level = selectedLevel.value[0]
        // if (selectedLevel.value[0] === 2) {
        //   level =
        // }
        const payload = {
          assesment_id: singleAssesment.value._id,
          code: newBpml,
          level: selectedLevel.value[0],
          project: "",
          type,
        }
        console.log(payload.type, 'type')
        dispatch('fetchAssesmentQuestions', payload)
      }
    }

    console.log(state.project.singleAssesment, 'assesment')

    onMounted(() => getQuestions());

    watch (() => state.project.selectedBpmlId, function () {
      getQuestions();
    });

    watch (() => state.project.bpmlQuestions, function () {
      getQuestions();
    });

    watch (() => state.project.selectedLevel, function () {
      getQuestions();
    });

    onUnmounted(() => state.project.assesmentQuestions = [])

    // const getBpmlQuestions = () => {
    //   if (state.project.selectedBpmlId) {
    //     dispatch('fetchQuestionsByBpml', state.project.selectedBpmlId)
    //   }
    // }

    const showAddModal = () => {
      addVisible.value = true
    }

    const onClickDeleteQuestion = (question) => {
      formState.id = question._id
      formState.question = question.question
      formState.level = question.level
      formState.answer = question.answer
      formState.assesmentAnswer = question.assesmentAnswer
      formState.assesment_id = question.assesment_id
      formState.bpmlCode = question.bpmlCode
      const payload = {
        id: formState.id,
        data: formState
      }
      dispatch('removeQuestion', payload);
    }
    
    const onClickEditQuestion = (question) => {
      console.log(question, 'question')
      formState.id = question._id
      formState.question = question.question
      formState.level = question.level
      formState.answer = question.answer
      formState.assesmentAnswer = question.assesmentAnswer
      formState.assesment_id = question.assesment_id
      formState.bpmlCode = question.bpmlCode
      updateAssesmentModal.value = true;
    }

    const handleCancel = () => {
      updateAssesmentModal.value = false;
      state.project.addAssesmentModal = false;
    }

    const handleEditQuestion = () => {
      const payload = {
        id: formState.id,
        data: formState
      }
      dispatch('editQuestion', payload);
      updateAssesmentModal.value = false;
    }

    const handleAddQuestion = () => {
      // formState.projectId = project.value.projectId.toString();
      // formState.bpml_id = state.project.selectedBpmlId
      // formState.project_id = 'undefined'
      formState.assesment_id = singleAssesment.value._id
      formState.bpmlCode = `${singleAssesment.value.project.projectCode}-${selectedBpmlObj.value.module}-${selectedBpmlObj.value.cat}-${selectedBpmlObj.value.codeId2}`
      if (singleAssesment.value.isTemplate) {
        console.log('istemplate')
        formState.bpmlCode = `${singleAssesment.value.industryCode}-${selectedBpmlObj.value.module}-${selectedBpmlObj.value.cat}-${selectedBpmlObj.value.codeId2}`
      }
      console.log(formState, 'formStaat')
      dispatch('createQuestion', formState);
      state.project.addAssesmentModal = false;
    }

    const onSwitchChange = (val) => {
      console.log(val)
      if (val) {
        formState.type = 'non-assesment' 
      } else {
        formState.type = 'assesment'
      }
    }

    // watch (() => state.project.selectedBpmlId, function () {
    //   getBpmlQuestions();
    // });

    const formState = reactive({
      id: null,
      question: null,
      level: null,
      answer: null,
      assesmentAnswer: null,
      bpmlCode: null,
      assesment_id: null,
      type: 'assesment',
    });

    return {
      activeKey,
      updateAssesmentModal,
      addVisible,
      formState,
      onClickDeleteQuestion,
      bpmlQuestions,
      showAddModal,
      onSwitchChange,
      visibilityType,
      selectedBpmlId,
      assesmentQuestions,
      user,
      handleCancel,
      handleAddQuestion,
      onClickEditQuestion,
      addAssesmentModal,
      handleEditQuestion,
    }
  }
}
</script>

<style>

</style>