<template>
  <ProjectDetailsWrapper>

          <!-- <ejs-documenteditorcontainer height="600px" :serviceUrl="serviceUrl" :enableToolbar='true'></ejs-documenteditorcontainer> -->
    <sdPageHeader>
      <template #title
        ><div v-if="bpmlVisible" key="1" class="project-header">
          <sdHeading as="h2">PROJECT EXECUTION, PROJECT ID {{ project.projectId }}</sdHeading>
          <!-- <sdButton v-if="!bpmlLoading" @click="handleGenerate" type="success" size="small">
            <sdFeatherIcons type="plus" size="14" /> <span>Generate</span>
          </sdButton> -->
        </div></template
      >
      <template #buttons>
        <div :key="1" class="project-action">
          <a @click="handleGenerate" v-if="project.isTemplate" :key="1" to="#" class="project-edit">
            <sdFeatherIcons type="plus" size="14" /> <span>Generate BPML</span>
            <!-- <sdFeatherIcons type="edit-3" size="14" />
            Edit -->
          </a>
          <!-- <a :key="2" to="#" class="project-remove">
            <sdFeatherIcons type="trash-2" size="14" />
            Remove
          </a> -->
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <div v-if="!project" class="spin">
        <a-spin />
      </div>
      <a-row v-else :gutter="25">
        <a-col v-if="bpmlVisible" :xxl="8" :xl="8" :md="24" :xs="24">
          <BpmlSidebar>
          <sdCards headless>
            <div class="" style="margin: 10px 15px; display: flex; justify-content: space-around">
              <div style="width: 100%">
                <a-select
                  v-model:value="selectedModule"
                  placeholder="BPML Modules"
                  style="width: 100%"
                  @change="handleModuleChange"
                  :options="options"
                ></a-select>
              </div>
              <!-- <div style="width: 100%" class="">
                <a-select
                  v-model:selectedKeys="selectedL2"
                  placeholder="All Sub Modules"
                  style="width: 100%"
                >
                <a-select-option v-for="L2 in bpmlL2s" :key="L2.bpmlL2" :value="L2.bpmlL2" @click="() => onMenuClick(L2.bpmlL2)">
                  <sdFeatherIcons type="inbox" size="14" style="margin-right: 5px;" /> {{L2.bpmlL2}}
                </a-select-option>
                </a-select>
              </div> -->
            </div>
            <!-- <div style="margin: 0 15px;">
              <a-select
                v-model:value="selectedModule"
                placeholder="BPML Modules"
                style="width: 100%"
                @change="handleModuleChange"
                :options="options"
              ></a-select>
            </div>
            <div style="margin: 10px 15px" class="">
              <a-select
                v-model:selectedKeys="selectedL2"
                placeholder="All Sub Modules"
                style="width: 100%"
              >
              <a-select-option v-for="L2 in bpmlL2s" :key="L2.bpmlL2" :value="L2.bpmlL2" @click="() => onMenuClick(L2.bpmlL2)">
                <sdFeatherIcons type="inbox" size="14" style="margin-right: 5px;" /> {{L2.bpmlL2}}
              </a-select-option>
              </a-select>
            </div> -->
            <div style="margin: 10px 15px">
              <a-input-search
                style="height: 45px"
                placeholder="Search BPML Code"
                @search="onBpmlSearch"
              />
            </div>

            <!-- <nav>
              <a-menu v-model:selectedKeys="selectedL2" mode="horizontal">
                <a-menu-item v-for="L2 in bpmlL2s" :key="L2.bpmlL2" :value="L2.bpmlL2" @click="() => onMenuClick(L2.bpmlL2)">
                  <sdFeatherIcons type="inbox" size="14" style="margin-right: 5px;" /> {{L2.bpmlL2}}
                </a-menu-item>
              </a-menu>
            </nav> -->
              <BpmlContent>
                <perfect-scrollbar
                style="max-height: 465px;"
                  :options="{
                    wheelSpeed: 1,
                    swipeEasing: true,
                    suppressScrollX: true,
                  }"
                >
                <div class="spin" v-if="bpmlLoading">
                  <a-spin />
                </div>
                <ul v-else>
                  <li v-for="bpml in bpmls" :key="bpml._id">
                    <ProjectCard>
                      <a href="#">
                      <sdCards :class="{ bpmlActive : active_bpml_el == bpml.bpmlCode }" @click="activate(bpml._id, bpml.bpmlCode)">
                        <grid-card :class="{ bpmlActive : active_bpml_el == bpml._id }" :bpml="bpml" :projectId="projectId" :project="project" />
                      </sdCards>
                      </a>
                    </ProjectCard>
                  </li>
                </ul>
                </perfect-scrollbar>
              </BpmlContent>
          </sdCards>
          </BpmlSidebar>
        </a-col> 
        <a-col v-if="bpmlVisible" :xxl="16" :xl="16" :md="24" :xs="24">
          <FaqWrapper>
            <sdCards>
              <template #title>
                <nav style="margin-bottom: 20px">
                  <a-menu v-model:selectedKeys="bodyMenu" mode="horizontal">
                    <a-menu-item key="doc" value="doc">
                      <sdFeatherIcons type="help-circle" size="15" style="margin-right: 2px;" /> <span style="font-size: 16px; color: #000;">Questions</span>
                    </a-menu-item>
                    <a-menu-item @click="() => hideShowBpml(false)" key="Testing" value="testing">
                      <sdFeatherIcons type="zoom-in" size="15" style="margin-right: 2px; color: #000;" /> <span style="font-size: 16px; color: #000;"> UT / SIT / UAT </span>
                    </a-menu-item>
                    <!-- <a-menu-item key="files" value="files">
                      <sdFeatherIcons type="upload-cloud" size="15" style="margin-right: 2px;" /> <span style="font-size: 16px; color: #000;">Videos</span>
                    </a-menu-item> -->
                  </a-menu>
                </nav>
              </template>
              <template #button>
                <!-- <sdButton size="small" class="btn-addFaq" outlined type="white">
                  <sdFeatherIcons type="plus" size="14" />
                  Add New
                </sdButton> -->
              </template>
              <!-- <div style="height=512px" class="" v-if="bodyMenu[0] === 'Testing'">
                <UT />
              </div> -->
              <div v-if="bodyMenu[0] === 'doc'" style="height=512px" class="">
                <Questions />
              </div>
              <div v-if="bodyMenu[0] === 'files'" style="height=512px" class="">
                <BpmlFileListCard />
              </div>
            </sdCards>
          </FaqWrapper>
        </a-col>
        <a-col v-if="!bpmlVisible" :xxl="24" :lg="24" :xs="24">
          <sdCards>
            <template #title>
                  <a @click="() => hideShowBpml(true)" style="font-size: 16px; color: #5f63f2; text-decoration: underline;"><sdFeatherIcons type="arrow-left" size="15" style="margin-right: 2px;" />  {{selectedBpml}}</a>
              <!-- <a-menu v-model:selectedKeys="Utbpmlselect" mode="horizontal">
                <a-menu-item key="bpml" value="bpml">
                </a-menu-item>
              </a-menu> -->
            </template>
            <FaqWrapper>
              <UT />
            </FaqWrapper>
          </sdCards>

        </a-col>
        <a-modal style="width: 75em" v-model:visible="modalDocVisible" title="Edit Document">
          <template #footer>
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button key="submit" type="primary" :loading="caseLoading" @click="handleOk">Update Document</a-button>
          </template>
          <a-form
            ref="modalFormRef"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
          >
            <a-form-item
              label="SharePoint Document Location URL:"
              name="sharePoint"
              :rules="[{ required: true, message: 'Please input SharePoint Document Location URL!' }]"
            >
              <a-input v-model:value="formState.sharePoint" />
            </a-form-item>
            <a-form-item
              label="Updated Document"
              name="updatedDoc"
              :rules="[{ required: true, message: 'Please upload Updated Document!' }]"
            >
              <a-upload
                v-model:file-list="fileList"
                name="file"
                :beforeUpload="beforeFileUpload"
                :headers="headers"
                :show-upload-list="false"
                @change="handleUploadChange"
              >
                <sdButton v-if="user.role === 'Admin'" @click="showAddModal" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px;" outlined type="primary">
                  <sdFeatherIcons type="upload" style=" margin-right: 15px" /> Upload File
                </sdButton>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-modal>
        <a-col v-if="bpmlVisible" :xxl="24" :lg="24" :xs="24">
          <sdCards style="height: 1245px">
            <template #title>
              <div class="" style="float: right">
                <div class="">
                  <sdButton v-if="user.role === 'Admin'" @click="downloadBpml" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px;" outlined type="primary">
                    <sdFeatherIcons type="edit" style=" margin-right: 15px" /> Edit Document
                  </sdButton>
                  <!-- <span style="font-size: 13px" > Document Display : </span> -->
                  <!-- <a-switch size="medium" style="margin-left: 15px; color: #FFFF !important" v-model:checked="docDisplay" >
                    <template #checkedChildren> <span style='color: #FFF; margin-right: 5px;'> Editor </span></template>
                    <template #unCheckedChildren> <span style='color: #FFF; margin-right: 5px;'> View Only </span></template>
                  </a-switch> -->
                  <sdButton v-if="user.role === 'Admin'" @click="refeshFile" size="small" class="btn-addFaq" style="float: right; margin-bottom: 10px; margin-right: 15px;" outlined type="primary">
                    <sdFeatherIcons type="refresh-ccw" style=" margin-right: 15px" /> Refresh Document
                  </sdButton>
                </div>
                <div class="">
                </div>
              </div>
            </template>
            <div v-if="selectedBpml" class="">
              <div class="" v-if="docDisplay">
                <syncDoc :projectId="project._id" :bpmlCode="active_bpml_el" :docName="`PROJECT ID ${project._id} - ${active_bpml_el}`" />
              </div>
              <div v-if="iframeLoading" class="spinnerContainer">
                <a-spin />
              </div>
              <div v-else class="">
                <!-- <iframe :src="`https://docs.google.com/viewer?url=${API_ENDPOINT}/public/PROJECT ID ${project.projectId}/${active_bpml_el}/PROJECT ID ${project._id} - ${active_bpml_el}.docx&embedded=true`" frameborder="0" width="100%" height="100%" ></iframe> -->
                <iframe :key="iframeLoading" :src="`https://docs.google.com/viewer?url=${API_ENDPOINT}/public${encodeURIComponent(bpmlLocalFile)}&embedded=true`" frameborder="0" width="100%" height="1005px" ></iframe>
                <!-- <iframe src="https://trimitrasistemsolusindo.sharepoint.com/sites/TMSDigitalImplementation/_layouts/15/Doc.aspx?sourcedoc={f781e98c-2bdf-4f6a-b0b9-fc7251fd2ad2}&action=embedview" width="100%" height="100%" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> document, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe> -->
                <!-- <VueDocPreview :value="`${API_ENDPOINT}/public/PROJECT ID ${project._id}/${active_bpml_el}/PROJECT ID ${project._id} - ${active_bpml_el}.docx`" type="office" /> -->
                <!-- <iframe :src="`${API_ENDPOINT}/public/PROJECT ID ${project._id}/${active_bpml_el}/PROJECT ID ${project._id} - ${active_bpml_el}.docx`" /> -->
              </div>
            </div>
            <div class="" v-else>
              <a-empty description="Please select a BPML" />
            </div>
          </sdCards>
        </a-col>
        <a-col v-if="bpmlVisible" :xxl="8" :xs="24">
          <FileListCard />
        </a-col>
        <a-col v-if="bpmlVisible" :xxl="16" :xs="24">
          <Suspense>
            <sdCards title="Comments">
              <AllPosts>
                <div class="post-content">
                  <div class="post-comments">
                    <div class="commentArea">
                      <div class="comment-form">
                        <!-- <img :src="require('@/static/img/chat-author/t2.jpg')" alt="" /> -->
                        <a-textarea v-model:value="commentInput" placeholder="Write comment...." />
                      </div>
                      <sdButton @click="onClickSendComment"
                        type="primary"
                        class="btn-send"
                      >
                        <sdFeatherIcons type="send" size="18" />
                      </sdButton>
                    </div>
                  </div>
                  <div :key="commentsUpdateKey" class="commentReplay">
                    <a-comment v-for="comment in comments" :key="comment._id">
                      <template #actions>
                        <span> Edit </span>
                         <a-popconfirm
                          title="Are you sure delete this comment?"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="() => onClickDeleteComment(comment._id)"
                          @cancel="cancel"
                        >
                          <a>
                            <span>Delete</span>
                          </a>
                          <!-- <a href="#">Delete</a> -->
                        </a-popconfirm>
                      </template>
                      <template #author><a>{{comment.user.username}}</a> <span key="comment-basic-reply-to" style="font-weight: bold; color: #5A5F85"> - {{comment.user.role}} </span></template>
                      <template #avatar>
                        <a-avatar
                            src="https://cdn4.iconfinder.com/data/icons/aami-web-internet/64/aami14-40-256.png"
                          />
                      </template>
                      <template #content>
                        <p>
                         {{comment.comment}}
                        </p>
                      </template>
                      <template #datetime>
                        <!-- {{ moment(comment.updatedAt) }} -->
                        <a-tooltip :title="moment(comment.updatedAt).format('YYYY-MM-DD HH:mm:ss')">
                          <span>{{ moment(comment.updatedAt).fromNow() }}</span>
                        </a-tooltip>
                      </template>
                    </a-comment>
                  </div>
                </div>
              </AllPosts>
            </sdCards>
          </Suspense>
        </a-col>
      </a-row>
    </Main>
  </ProjectDetailsWrapper>
</template>
<script>

import { computed, onMounted, defineComponent, defineAsyncComponent, ref, reactive, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Swal from 'sweetalert2';

import { ProjectDetailsWrapper, BpmlSidebar, BpmlContent, ProjectCard, AllPosts } from './style';
import FileListCard from './overview/FileListCard';
import BpmlFileListCard from './overview/BpmlFileListCard';
import { Main } from '../styled';
import { FaqWrapper } from '../pages/style';
import syncDoc from '../../syncDoc.vue'
import UT from './overview/UT.vue'
import { API_ENDPOINT } from '@/services/api/axiosConfig.js';
import Questions from './overview/Questions.vue'

const GridCard = defineAsyncComponent(() => import('./overview/GridCard'));
const moment = require('moment');

const ProjectDetails = defineComponent({
  name: 'ProjectDetails',
  components: { BpmlFileListCard, syncDoc, PerfectScrollbar, ProjectCard, ProjectDetailsWrapper, BpmlContent, BpmlSidebar, Main, FileListCard, GridCard, FaqWrapper, AllPosts, UT, Questions },
  
  data() {
    return {
      API_ENDPOINT,
      current: ['mail'],
      serviceUrl:'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/',
    };
  },
  provide:{
    // DocumentEditorContainer: [Toolbar]
  },
  methods: {
    moment,
  },
  setup() {
    const { state, dispatch } = useStore();
    const project = computed(() => state.project.singleData);
    const docDisplay = ref(false)
    const { params, matched } = useRoute();
    const { title, content } = computed(() => state.project.singleData);
    const bpmls = computed(() => state.project.filteredBpmls)
    const bpmlL2s = computed(() => state.project.bpmlL2s);
    const bpmlLoading = computed(() => state.project.bpmlLoading);
    const selectedModule = computed(() => state.project.selectedModule);
    const selectedBpml = computed(() => state.project.selectedBpml);
    const iframeUpdate = computed(() => state.project.iframeUpdate);
    const iframeLoading = computed(() => state.project.iframeLoading);
    const comments = computed(() => state.project.comments);
    const user = computed(() => state.auth.currentAccount);
    const commentsUpdate = computed(() => state.auth.commentsUpdate);
    let selectedL2 = ref(['']);
    let bodyMenu = ref(['doc']);
    let commentsUpdateKey = ref(0);
    let bpmlLocalFile = ref();
    let bpmlVisible = ref(true);
    let commentInput = ref('');
    let Utbpmlselect = ref(['bpml'])
    let active_bpml_el = ref('');
    let modalDocVisible = ref(false);
    const testCategory = ref('jack');
    
    console.log(project, 'singledata')

    const showEditDocumentModal = () => {
      modalDocVisible.value = true
    }



    window.scrollTo(0,0);

    onMounted(() => dispatch('filterSinglePage', parseInt(params.id, 10)));
    onMounted(() => dispatch("decodeToken"));
    onMounted(() => dispatch('fetchProject', params.id));
    onMounted(() => dispatch('fetchProjectBpmls', params.id));
    onMounted(() => dispatch('fetchBpmlL2s'));
    onMounted(() => dispatch('fetchCommentsByProject', params.id))
    onMounted(() => window.scrollTo(0,0));

    onUnmounted(() => { state.project.filteredBpmls = []; state.project.selectedBpml = null; state.project.selectedBpmlId = null })

    watch (() => state.project.commentsUpdate, function () {
      console.log('comments updated')
      commentsUpdateKey.value += 1;
    });

    watch (() => state.project.iframefolderloaded, function () {
      refeshFile();
    })

    const onClickDeleteComment = (id) => {
      dispatch('removeProjectComment', id)
    }

    const onClickSendComment = () => {
      console.log(selectedBpml.value, 'selectedbpmla')
      const payload = {
        user: user.value._id,
        project: params.id,
        comment: commentInput.value,
        bpml: selectedBpml.value
      }
      dispatch('addProjectComment', payload)
    }


    const hideShowBpml = (val) => {
      if (selectedBpml.value){
        Swal.fire({
          title: 'Loading BPML Content.',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
          }
        }).then(() => {
          bpmlVisible.value = val
          bodyMenu.value = ['doc']
        })
      }
    }

    const options = ref([
      { value: '', label: 'Select All' },
      { value: 'FI', label: 'FI' },
      { value: 'CO', label: 'CO' },
      { value: 'MM', label: 'MM' },
      { value: 'PM', label: 'PM' },
      { value: 'PP', label: 'PP' },
      { value: 'PS', label: 'PS' },
      { value: 'QM', label: 'QM' },
      { value: 'SD', label: 'SD' },
    ]);

    const activeKey = ref(['1']);
    const handleModuleChange = (val) => {
      state.project.selectedModule = val
      dispatch('filterByModules', val)
    }

    const downloadBpml = () => {
      for (const bpml of bpmls.value) {
        if (bpml.bpmlCode === selectedBpml.value) {
          const newBpml = {...bpml}
            const bpmlBodyFile = bpml.bpmlBodyFile
            if (bpmlBodyFile) {
            console.log(`${bpmlBodyFile}`, 'videofile')
            var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
              // PRODUCtiON WARNING
              // if (bpmlBodyFile.split(' ') === 5) {
                
              //   }
              // let newLineCopy = bpmlBodyFile.split(' ');
              // newLineCopy[4] = ".docx?web=1&e=UfaMUe";
              // newLineCopy = newLineCopy.join(" ");
              // console.log(newLineCopy, 'newlinecopy')
              
              console.log(bpmlBodyFile, 'split')
              window.close();
              const w = window.open(`${bpmlBodyFile}?csf=1&web=1&e=GBeZXq`, '_blank', strWindowFeatures)
              const timer = setInterval(() => {
                if (w.closed) {
                  clearInterval(timer);
                  refeshFile();
                  return;
                }
              }, 1000)
              console.log(newBpml, 'selectedBpmlLoad')
              console.log(API_ENDPOINT, 'API_ENDPOINT')
            Swal.hideLoading();
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Unavailable.',
                text: "File Not Found.",
              })
            }
          }
        }
    }

    const refeshFile = () => {
      let filename = ''
      let moduleName = ''
      let bpmlTemplateCode = ''
      let bpmlId = ''
      for (const bpml of bpmls.value) {
        if (bpml.bpmlCode === selectedBpml.value) {
          filename = encodeURIComponent(bpml.filename);
          bpmlTemplateCode = bpml.code
          moduleName = bpml.module;
          console.log(bpml.bpmlBodyFile, 'bodyfile')
          bpmlLocalFile.value = bpml.bpmlLocalFile
          bpmlId = bpml._id
        }
      }
      filename = filename.replace(bpmlTemplateCode, project.value.projectCode)
      console.log(moduleName, 'file code')
      console.log(state.project.selectedBpml, 'oldCode code')
      console.log(state.project.selectedBpml, 'oldCode code')
      console.log(filename, "filename code")
      dispatch('makeSharepointFolder', { code: project.value.projectCode, module: moduleName, projectId: project.value.projectId, bpml: filename, oldCode: project.value.projectCode, refresh: true, newBpml: state.project.selectedBpml, bpmlId: bpmlId })
    }

    const activate = (id, el) => {
      dispatch('fetchCommentsByProject', { project: params.id, bpml: el })
        active_bpml_el.value = el;
        state.project.selectedBpml = el
        state.project.selectedBpmlId = id
        const docName = `PROJECT ID ${project.value._id} - ${el}.sfdt`
        // PROJECT ID ${project.projectId} - ${active_bpml_el}
        state.project.selectedBpmlBodyPath = `/PROJECT ID ${project.value._id}/${el}/${docName}`
        console.log(state.project.selectedBpml, 'selectedbpml');
        let filename = ''
        let moduleName = ''
        let bpmlTemplateCode = ''
        let bpmlId = ''
        for (const bpml of bpmls.value) {
          if (bpml.bpmlCode === selectedBpml.value) {
            filename = encodeURIComponent(bpml.filename);
            bpmlTemplateCode = bpml.code
            moduleName = bpml.module;
            bpmlLocalFile.value = bpml.bpmlLocalFile
            bpmlId = bpml._id
          }
        }
        console.log(state.project.selectedBpml, 'value code')
        dispatch('makeSharepointFolder', { newBpml: state.project.selectedBpml ,code: project.value.projectCode, module: moduleName, projectId: project.value.projectId, bpml: filename, oldCode: bpmlTemplateCode, refresh: 'false', bpmlId: bpmlId })
    }

    const handleGenerate = () => {
      if (bpmls.value.length > 0) {
        Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "BPML Codes already generated.",
      })
      } else {
        dispatch('insertProjectBpmls', { projectId: params.id, industry: project.value.industry, projectCode: project.value.projectCode })
        Swal.fire({
          title: 'Generating BPML, please wait...',
          timer: 99999999999999,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
          }
        }).then(() => {
        })
      }
    }
    const onBpmlSearch = (searchValue) => {
      state.project.selectedL2 = ""
      selectedL2.value = [""]
      dispatch('filterByBpml', searchValue)
    }
    const onMenuClick = (item) => {
      console.log(item, 'item')
      state.project.selectedL2 = item
      dispatch('filterBpmlByL2', item)
    }

    const formState = reactive({
      sharePoint: null,
      updatedDoc: null,
    });

    return {
      title,
      content,
      formState,
      activeKey,
      handleGenerate,
      activate,
      bpmlVisible,
      encodeURIComponent,
      bpmlLocalFile,
      comments,
      downloadBpml,
      bpmls,
      iframeLoading,
      testCategory,
      onBpmlSearch,
      selectedBpml,
      showEditDocumentModal,
      bpmlL2s,
      selectedL2,
      bodyMenu,
      options,
      modalDocVisible,
      commentsUpdateKey,
      iframeUpdate,
      Utbpmlselect,
      onMenuClick,
      project,
      params,
      commentInput,
      user,
      matched,
      projectId: project.value._id,
      active_bpml_el,
      handleModuleChange,
      hideShowBpml,
      docDisplay,
      selectedModule,
      onClickDeleteComment,
      commentsUpdate,
      bpmlLoading,
      refeshFile,
      onClickSendComment,
    };
  },
});

export default ProjectDetails;
</script>
<style scoped>
.spinnerContainer {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
#scaled-frame {
        transform: scale(1);
        height: 605px;
        width: 100%;
      }
</style>
