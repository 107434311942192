<template>
  <sdModal
    :type="modalType"
    title="Edit Assesment"
    :visible="visible"
    :onCancel="handleCancel"
  >
    <div class="project-modal">
      <BasicFormWrapper>
        <a-form
          :model="formState"
          :layout="formState.layout"
        >
        <a-form-item name="company" label="Company">
              <a-input
              v-model:value="formState.company"
              placeholder="Company"
              />
          </a-form-item>
          <a-form-item name="projectMembers" label="Assesment Members">
              <a-select
                v-model:value="newMembers"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="[...members].map((x) => ({ value: x.username }))"
              ></a-select>
            </a-form-item>
          <a-form-item name="members" label="Account Manager">
              <a-select
                v-model:value="selectedMember"
                style="width: 100%"
                placeholder="Please select"
                :options="[...members].map((x) => ({ value: x.username }))"
                @change="handleMembersChange"
              ></a-select>
          </a-form-item>
          <!-- <a-form-item name="assesmentId" label="Assesment ID">
              <a-input-number
              v-model:value="formState.assesmentId"
              placeholder="Assesment ID"
              />
          </a-form-item> -->
          <a-form-item name="industry" label="Industry">
            <a-select v-model:value="formState.industry" style="width: 100%">
              <a-select-option @click="() => handleIndustryChange(industry.bpmlTemplateCode)" v-for="industry in industries" :key="industry._id" :value="industry.industry">
                {{ industry.industry + " (" + industry.bpmlTemplateCode + ")" }}
              </a-select-option>
              <!-- <a-select-option value="T_MFG">Manufacturing (T_MFG)</a-select-option>
              <a-select-option value="T_MNG">Mining (T_MNG)</a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item name="projectCode" label="Project Code">
            <a-input
              v-model:value="formState.projectCode"
              placeholder="Project Code (ex: MFGA)"
            />
          </a-form-item>
          <a-form-item name="Category" label="Project Category">
            <a-select
                v-model:value="formState.category"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="categoryOptions"
              ></a-select>
            <!-- <a-checkbox-group
              v-model:value="formState.category"
              :options="categoryOptions"
            /> -->
          </a-form-item>
          <a-form-item name="status" label="Status">
            <a-select v-model:value="formState.status" style="width: 100%">
              <a-select-option value="Open">Open</a-select-option>
              <a-select-option value="In Progress">In Progress</a-select-option>
              <a-select-option value="Close">Close</a-select-option>
            </a-select>
          </a-form-item>
          <a-row :gutter="15">
            <a-col :md="24">
              <a-form-item name="startDate" label="Start Date">
                <a-date-picker
                  v-model:value="formState.startDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24">
              <a-form-item>
                <ProjectModalFooter>
                  <sdButton
                    size="default"
                    type="primary"
                    key="submit"
                    @click="handleOk"
                  >
                    Update Assesment
                  </sdButton>
                  <sdButton
                    size="default"
                    type="light"
                    key="back"
                    outlined
                    @click="handleCancel"
                  >
                    Cancel
                  </sdButton>
                </ProjectModalFooter>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>
</template>
<script>
import propTypes from "vue-types";
import { BasicFormWrapper } from "../../../view/styled";
import { ProjectModalFooter } from "../style";
import { reactive, ref, toRefs, defineComponent, computed, watch } from "vue";
import { useStore } from 'vuex';
const moment = require('moment');

const categoryOptions = [
  {
    label: "Implementation",
    value: "Implementation",
  },
  {
    label: "SAP Upgrade",
    value: "SAP Upgrade",
  },
  {
    label: "Change Request",
    value: "Change Request",
  },
  {
    label: "Roll Out",
    value: "Roll Out",
  },
];

const dateFormat = "MM/DD/YYYY";

const CreateAssesment = defineComponent({
  name: "CreateAssesment",
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const modalType = ref("primary");
    const checked = ref([]);
    const { onCancel } = toRefs(props);
    const formatter = (value) => {
      return `${value}%`;
    };
    dispatch("fetchUsers");
    dispatch("fetchIndustries");
    dispatch("decodeToken");
    const marks = ref({
      0: '0%',
      50: '50%',
      80: '80%',
      100: {
        style: {
          color: '#20C997',
        },
        label: '100%',
      },
    });
    const members = computed(() => state.auth.users);
    const user = computed(() => state.auth.currentAccount);
    const industries = computed(() => state.project.industries);
    const selectedAssesment = computed(() => state.project.selectedAssesment);
    const selectedMember = ref([])
    const existingMembers = ref([]);
    const newMembers = ref([]);
    const formState = reactive({
      assesmentId: "",
      company: "",
      PO: "",
      accountManager: "",
      category: ["Implementation"],
      product: "SAP",
      members: null,
      industry: null,
      projectCode: null,
      industryCode: "",
      updatedBy: null,
      startDate: "",
      status: "Open",
      layout: "vertical",
    });


    const handleMembersChange = (value) => {
      console.log(`selected ${value}`);
      formState.accountManager = value
    };

    const handleIndustryChange = (val) => {
      formState.industryCode = val
    }

    const loadMembers = () => {
      existingMembers.value = []
      newMembers.value = []
      for (const member of members.value) {
        if (member.assesments.find(el => el.assesment_id === selectedAssesment.value._id)) {
          existingMembers.value.push(member.username)
          newMembers.value.push(member.username)
        }
      }
    }

    const onAssesmentSelected = () => {
      loadMembers();
      formState.assesmentId = selectedAssesment.value.assesmentId
      formState.company = selectedAssesment.value.project.company
      formState.category = [];
      formState.projectCode = selectedAssesment.value.project.projectCode
      formState.accountManager = selectedAssesment.value.accountManager
      selectedMember.value = selectedAssesment.value.accountManager
      formState.startDate = moment(selectedAssesment.value.startDate)
      formState.status = selectedAssesment.value.status
      formState.isTemplate = selectedAssesment.value.isTemplate
      formState.industry = selectedAssesment.value.industry
      formState.industryCode = selectedAssesment.value.industryCode
      for (const cat of selectedAssesment.value.project.category) {
        formState.category.push(
          cat.category
        )
      }
      console.log(formState, 'formstaat')
    }

    watch(() => state.project.selectedAssesment, function() {
      onAssesmentSelected();
    });

    const handleOk = () => {
      const newAssesmentForm = {
        project: {
          company: formState.company,
          category: [],
          projectCode: formState.projectCode,
        },
        members: [],
        removeMembers: [],
        accountManager: formState.accountManager,
        assesmentId: formState.assesmentId,
        startDate: moment(formState.startDate._d).format("YYYY-MM-DD"),
        status: formState.status,
        isTemplate: false,
        updatedBy: user.value.username,
        industry: formState.industry,
        industryCode: formState.industryCode,
      }
      console.log(formState.startDate, 'startDate')
      for (const category of formState.category) {
        newAssesmentForm.project.category.push({
          category: category
        })
      }
      for (const member of newMembers.value) {
        if (!existingMembers.value.find(el => el === member)) {
          newAssesmentForm.members.push(member)
        }
      }
      console.log(newAssesmentForm.members, 'members')
      for (const member of existingMembers.value) {
        if (!newMembers.value.find(el => el === member)) {
          newAssesmentForm.removeMembers.push(member)  
        }
      }
      console.log(newAssesmentForm.removeMembers, 'removemembers')
      const payload = {
        id: selectedAssesment.value._id,
        data: newAssesmentForm,
      }
      dispatch('editAssesment', payload);
      onAssesmentSelected();
      onCancel.value();
    };

    const handleCancel = () => {
      onCancel.value();
    };

    return {
      categoryOptions,
      selectedMember,
      handleOk,
      handleCancel,
      modalType,
      marks,
      industries,
      handleIndustryChange,
      formatter,
      handleMembersChange,
      newMembers,
      members,
      checked,
      dateFormat,
      formState,
    };
  },
});

export default CreateAssesment;
</script>
