<template>
  <sdModal
    :type="modalType"
    title="Create Assesment"
    :visible="visible"
    :onCancel="handleCancel"
  >
    <div class="project-modal">
      <BasicFormWrapper>
        <a-form
          :model="formState"
          :layout="formState.layout"
        >
        <a-form-item v-if="assesmentType" name="company" label="Company">
              <a-input
              v-model:value="formState.company"
              placeholder="Company"
              />
          </a-form-item>
          <a-form-item name="assesmentMembers" label="Assesment Members">
              <a-select
                v-model:value="value"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="[...members].map((x) => ({ value: x.username }))"
                @change="handleAssesmentMembersChange"
              ></a-select>
            </a-form-item>
          <a-form-item name="members" label="Account Manager">
              <a-input
              v-model:value="formState.accountManager"
              placeholder="Acc Manager"
              />
          </a-form-item>
          <!-- <a-form-item name="assesmentId" label="Assesment ID">
              <a-input-number
              v-model:value="formState.assesmentId"
              placeholder="Assesment ID"
              />
          </a-form-item> -->
          <a-form-item name="industry" label="Industry">
            <a-select v-model:value="formState.industry" style="width: 100%">
              <a-select-option @click="() => handleIndustryChange(industry.bpmlTemplateCode)" v-for="industry in industries" :key="industry._id" :value="industry.industry">
                {{ industry.industry + " (" + industry.bpmlTemplateCode + ")" }}
              </a-select-option>
              <!-- <a-select-option value="T_MFG">Manufacturing (T_MFG)</a-select-option>
              <a-select-option value="T_MNG">Mining (T_MNG)</a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item v-if="assesmentType" name="projectCode" label="Project Code">
            <a-input
              v-model:value="formState.projectCode"
              placeholder="Project Code (ex: MFGA)"
            />
          </a-form-item>
          <a-form-item v-if="assesmentType" name="Category" label="Project Category">
            <a-select
                v-model:value="formState.category"
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                :options="categoryOptions"
              ></a-select>
            <!-- <a-checkbox-group
              v-model:value="formState.category"
              :options="categoryOptions"
            /> -->
          </a-form-item>
          <a-form-item name="status" label="Status">
            <a-select v-model:value="formState.status" style="width: 100%">
              <a-select-option value="Open">Open</a-select-option>
              <a-select-option value="In Progress">In Progress</a-select-option>
              <a-select-option value="Close">Close</a-select-option>
            </a-select>
          </a-form-item>
          <a-row :gutter="15">
            <a-col :md="24">
              <a-form-item name="startDate" label="Start Date">
                <a-date-picker
                  v-model:value="formState.startDate"
                  placeholder="mm/dd/yyyy"
                  :format="dateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24">
              <a-form-item>
                <ProjectModalFooter>
                  <sdButton
                    size="default"
                    type="primary"
                    key="submit"
                    @click="handleOk"
                  >
                    Add New Assesment
                  </sdButton>
                  <sdButton
                    size="default"
                    type="light"
                    key="back"
                    outlined
                    @click="handleCancel"
                  >
                    Cancel
                  </sdButton>
                </ProjectModalFooter>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>
</template>
<script>
import propTypes from "vue-types";
import { BasicFormWrapper } from "../../../view/styled";
import { ProjectModalFooter } from "../style";
import { reactive, ref, toRefs, defineComponent, computed } from "vue";
import { useStore } from 'vuex';
const moment = require('moment');

const categoryOptions = [
  {
    label: "Implementation",
    value: "Implementation",
  },
  {
    label: "SAP Upgrade",
    value: "SAP Upgrade",
  },
  {
    label: "Change Request",
    value: "Change Request",
  },
  {
    label: "Roll Out",
    value: "Roll Out",
  },
];

const dateFormat = "MM/DD/YYYY";

const CreateAssesment = defineComponent({
  name: "CreateAssesment",
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const modalType = ref("primary");
    const checked = ref([]);
    const { onCancel } = toRefs(props);
    const formatter = (value) => {
      return `${value}%`;
    };
    dispatch("fetchUsers");
    dispatch("fetchIndustries");
    dispatch("decodeToken");
    const marks = ref({
      0: '0%',
      50: '50%',
      80: '80%',
      100: {
        style: {
          color: '#20C997',
        },
        label: '100%',
      },
    });
    const members = computed(() => state.auth.users);
    const user = computed(() => state.auth.currentAccount);
    const industries = computed(() => state.project.industries);
    const assesmentType = computed(() => state.project.assesmentType);
    const formState = reactive({
      assesmentId: "",
      company: "",
      PO: "",
      accountManager: "",
      category: ["Implementation"],
      product: "SAP",
      members: null,
      industry: null,
      projectCode: null,
      industryCode: "",
      startDate: "",
      updatedBy: null,
      status: "Open",
      layout: "vertical",
    });

    const handleAssesmentMembersChange = (value) => {
      console.log(`selected ${value}`);
      formState.members = value
      // formState.members.split(',');
      // console.log(formState.members)
    };

    const handleMembersChange = (value) => {
      console.log(`selected ${value}`);
      formState.accountManager = value
    };

    const handleIndustryChange = (val) => {
      formState.industryCode = val
    }

    const handleOk = () => {
      console.log(user.value, 'uservalue')
      const newAssesmentForm = {
        project: {
          company: formState.company,
          category: [],
          projectCode: formState.projectCode,
        },
        accountManager: formState.accountManager,
        members: formState.members,
        startDate: moment(formState.startDate.$d).format("YYYY-MM-DD"),
        status: formState.status,
        isTemplate: !assesmentType.value,
        industry: formState.industry,
        updatedBy: user.value.username,
        industryCode: formState.industryCode,
      }
      console.log(newAssesmentForm)
      for (const category of formState.category) {
        newAssesmentForm.project.category.push({
          category: category
        })
      }
      newAssesmentForm.members.push(user.value.username)

      dispatch('insertAssesment', newAssesmentForm);
      onCancel.value();
      formState.projectCode = null
      formState.company = null
      formState.startDate = ""
    };

    const handleCancel = () => {
      onCancel.value();
    };

    return {
      categoryOptions,
      value: ref([]),
      handleOk,
      handleCancel,
      modalType,
      marks,
      industries,
      handleAssesmentMembersChange,
      handleIndustryChange,
      formatter,
      assesmentType,
      handleMembersChange,
      members,
      checked,
      dateFormat,
      formState,
    };
  },
});

export default CreateAssesment;
</script>
